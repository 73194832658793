export const theadData = [
  { id: "date", label: "Date", minWidth: 100, align: "center" },
  {
    id: "total_quantity",
    label: "Total Quantity",
    minWidth: 200,
    align: "center",
  },
  { id: "uploaded_at", label: "Updated At", minWidth: 200, align: "center" },
];
