import React, { useEffect, useState } from "react";
import "../../css/basictable.css";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PurchaseForecastMonths from "./purchaseForecastMonths";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
import { TextField } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

export default function PurchaseForecastItem({ data, idx, ...props }) {
  const {
    id,
    name,
    total_sales,
    sales_average_6_months,
    inventory,
    in_transit,
    in_factory,
    total_inventory,
    suggested_order_amount,
    sri_part,
    add,
  } = data;
  const { setData, data2, setOrderAmount, orderAmount } = props;
  const [suggestedAmount, setSuggestedAmount] = useState(
    suggested_order_amount
  );

  useEffect(() => {
    const test = () => {
      setSuggestedAmount(suggested_order_amount);
    };
    test();
  }, [suggested_order_amount]);

  const postPartUpdateStatus = (part_id, requested_quantity) => {
    let idx2 = data2.results.findIndex((ele) => ele.id === part_id);
    let copy = { ...data2 };
    copy.results[idx2]["add"] = false;
    copy.results[idx2]["suggested_order_amount"] = requested_quantity;
    setOrderAmount(orderAmount + copy.results[idx2]["suggested_order_amount"]);
    setData(copy);
  };
  const deletePartUpdateStatus = (part_id) => {
    let idx2 = data2.results.findIndex((ele) => ele.id === part_id);
    let copy = { ...data2 };
    copy.results[idx2]["add"] = true;
    setOrderAmount(orderAmount - copy.results[idx2]["suggested_order_amount"]);
    setData(copy);
  };
  const renderSubmitButton = () => {
    return add ? (
      <TableCell align="center">
        <Tooltip title="Add">
          <IconButton
            onClick={() =>
              postPartUpdateStatus(id, Math.round(suggestedAmount))
            }
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    ) : (
      <TableCell align="center">
        <Tooltip title="Remove">
          <IconButton onClick={() => deletePartUpdateStatus(id)}>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    );
  };

  return (
    <TableRow className="Cell">
      <TableCell align="center">{name}</TableCell>
      <PurchaseForecastMonths sri_part={sri_part} months={props.months} />
      <TableCell align="center" className="border-left">
        {total_sales}
      </TableCell>
      <TableCell align="center">{sales_average_6_months}</TableCell>
      <TableCell align="center" className="border-left">
        {inventory}
      </TableCell>
      <TableCell align="center">{in_transit}</TableCell>
      <TableCell align="center">{in_factory}</TableCell>
      <TableCell align="center" className="border-right">
        {total_inventory}
      </TableCell>
      {props.blockDone && (
        <TableCell align="center" className="border-right">
          <TextField
            name={"suggested_amount"}
            label={"Suggested Amount"}
            type="number"
            variant="standard"
            disabled={!add}
            onChange={(e) =>
              e.target.value < 0
                ? setSuggestedAmount(0)
                : setSuggestedAmount(e.target.value)
            }
            value={suggestedAmount}
          />
        </TableCell>
      )}
      {props.blockDone && renderSubmitButton()}
    </TableRow>
  );
}
