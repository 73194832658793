import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import axiosInstance from "../../hooks/usePostAxios";

export default function PurchaseOrderFilesItem({ data }) {
  const {
    name,
    status,
    type,
    id,
    original_filename,
    purchaseorder,
    created_at,
  } = data;
  let statusText;
  if (status === "P") {
    statusText = "Pending";
  } else if (status === "A") {
    statusText = "Accepted";
  } else {
    statusText = "Cancelled";
  }
  const [updateStatus, setUpdateStatus] = useState(status);
  const admin = localStorage.getItem("role") === "true";
  const handleChange = (e) => {
    setUpdateStatus(e.target.value);
    axiosInstance
      .put(`/esd/files/${id}/`, {
        name: name,
        status: e.target.value,
        type: type,
        original_filename: original_filename,
        purchaseorder: purchaseorder,
      })
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <TableRow>
      <TableCell align="center">{name}</TableCell>
      {admin ? (
        <TableCell align="center">
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={updateStatus}
            defaultValue={10}
            label="Status"
            onChange={(e) => handleChange(e)}
            disabled={!admin}
          >
            <MenuItem value={"P"}>Pending</MenuItem>
            <MenuItem value={"A"}>Accepted</MenuItem>
            <MenuItem value={"C"}>Cancelled</MenuItem>
          </Select>
        </TableCell>
      ) : (
        <TableCell align="center">{statusText}</TableCell>
      )}
      <TableCell align="center">{type}</TableCell>
      <TableCell align="center">{created_at}</TableCell>
      <TableCell align="center">
        <a
          href={`https://krameramerica.exavault.com/p/esd_app/invoice_upload/${name}`}
        >
          <DownloadIcon />
        </a>
      </TableCell>
    </TableRow>
  );
}
