export const monthNamesFunc = () => {
  let monthNames = [];
  let today = new Date();
  let currentMonth = today.getMonth();
  let currentYear = today.getFullYear();
  let lastYear = currentYear - 1;
  let counter = 1;
  while (counter <= 12) {
    let counterName;
    if (counter < 10) {
      counterName = "0" + counter.toString();
    } else {
      counterName = counter;
    }
    if (currentMonth >= counter) {
      monthNames.push(`${currentYear}-${counterName}`);
    } else {
      monthNames.push(`${lastYear}-${counterName}`);
    }
    counter += 1;
  }
  return monthNames;
};
