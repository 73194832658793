import React, { useState, useEffect } from "react";
import "../../css/basictable.css";
import TableCell from "@mui/material/TableCell";

export default function PurchaseForecastMonths({ sri_part, months }) {
  const [monthObj, setMonthObj] = useState();

  useEffect(() => {
    const renderDates = () => {
      let newObj = {};
      let copy = { ...sri_part };

      for (const property2 in copy) {
        newObj[copy[property2].date.slice(0, 7)] =
          copy[property2].total_quantity;
      }
      let monthCount = [];
      for (const property2 in copy) {
        let date = copy[property2].date.slice(0, 7);
        monthCount.push(date);
      }

      if (months.length !== Object.keys(newObj).length) {
        months.forEach((current_month) => {
          if (!newObj[current_month]) newObj[current_month] = 0;
        });
      }
      setMonthObj(newObj);
    };
    const setData = () => {
      renderDates();
    };
    setData();
  }, [sri_part, setMonthObj, months]);

  // const renderThings = () => {
  //     let components = []
  //     months.forEach((month) => {
  //         components.push(
  //         <TableCell align="center">{monthObj[month]}</TableCell>)
  //     })
  //     components.map((Item) => {
  //         return <Item/>
  //     })

  //     months.forEach((month) => {
  //         components.push(
  //         <TableCell align="center">{monthObj[month]}</TableCell>)
  //     })

  // }

  return (
    <>
      {monthObj && (
        <>
          <TableCell align="center">{monthObj[months[0]]}</TableCell>
          <TableCell align="center">{monthObj[months[1]]}</TableCell>
          <TableCell align="center">{monthObj[months[2]]}</TableCell>
          <TableCell align="center">{monthObj[months[3]]}</TableCell>
          <TableCell align="center">{monthObj[months[4]]}</TableCell>
          <TableCell align="center">{monthObj[months[5]]}</TableCell>
          {/* <TableCell align="center">{monthObj[months[6]]}</TableCell> */}
        </>
      )}
    </>
  );
}
