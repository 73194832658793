import React from "react";
import { useHistory } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
export default function PurchaseOrdersList({ data, hover }) {
  const {
    id,
    name,
    created_at,
    customer_company,
    freight_type,
    total_quantity,
    total_parts,
    status,
    file_status,
  } = data;
  const { push } = useHistory();
  let color;
  let text;
  let fileText;
  let fileColor;
  if (status === "C") {
    color = "bg-danger";
    text = "Closed";
  } else if (status === "P") {
    color = "bg-warning";
    text = "Pending";
  } else if (status === "a") {
    color = "bg-success";
    text = "Accepted";
  }
  if (file_status === "C") {
    fileColor = "bg-success";
    fileText = "Completed";
  } else if (file_status === "P") {
    fileColor = "bg-warning";
    fileText = "Pending";
  }
  return (
    <TableRow
      className={hover}
      onClick={() => push(`/purchaseorders/${id}/items`)}
    >
      <TableCell align="center">{name}</TableCell>
      <TableCell align="center">{customer_company}</TableCell>
      <TableCell align="center" className={color}>
        {text}
      </TableCell>
      <TableCell align="center" className={fileColor}>
        {fileText}
      </TableCell>
      <TableCell align="center">{freight_type}</TableCell>
      <TableCell align="center">{total_quantity}</TableCell>
      <TableCell align="center">{total_parts}</TableCell>
      <TableCell align="center">{created_at}</TableCell>
    </TableRow>
  );
}
