import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

export default function NavBarAdmin({ location, auth, customers, loading }) {
  let activeProducts;
  let activeSales;
  let activePart;
  let activeNewPart;
  location === "productionorders" || location === "productionorder"
    ? (activeProducts = true)
    : (activeProducts = false);
  location === "salesreportsdetail" || location === "salesreports"
    ? (activeSales = true)
    : (activeSales = false);

  location === "part" ? (activePart = true) : (activePart = false);
  location === "newpart" ? (activeNewPart = true) : (activeNewPart = false);

  return (
    <>
      <Navbar className="h5" bg="light" expand="lg">
        <Container className="font-size">
          <Navbar.Brand
            href="/"
            className="font-size d-flex align-items-baseline"
          >
            <img
              alt=""
              src="/logo4.png"
              width="230"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "200px" }}
            navbarScroll
          >
            <NavDropdown
              active={activeProducts}
              title="Production Orders"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/productionorders">All</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/productionorders/O">
                Open
              </NavDropdown.Item>
              <NavDropdown.Item href="/productionorders/F">
                Fulfilled
              </NavDropdown.Item>
              <NavDropdown.Item href="/productionorders/C">
                Closed
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/productionorder/new">
                New Production Order
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              active={activePart}
              title="Purchase Orders"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/purchaseorders">
                Purchase Orders
              </NavDropdown.Item>

              <NavDropdown
                key={"right"}
                id="dropdown-button-drop-end"
                drop="end"
                title={`New Purchase Order `}
              >
                {!loading &&
                  customers.map((customer, idx) => {
                    return (
                      <NavDropdown.Item
                        key={idx}
                        href={"/purchaseorders/new/" + customer.id}
                      >
                        {customer.company_name}
                      </NavDropdown.Item>
                    );
                  })}
              </NavDropdown>
            </NavDropdown>
            <NavDropdown
              active={activeSales}
              title="Reports"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/reports/sales">Sales</NavDropdown.Item>
              <NavDropdown.Item href="/reports/inventory">
                Inventory
              </NavDropdown.Item>
              <NavDropdown.Item href="/reports/forecast">
                Forecast
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              active={activeNewPart}
              title="Parts"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/parts">All Parts</NavDropdown.Item>
              <NavDropdown.Item href="/part/new">Add New Part</NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link active={activeNewPart} href="/newpart">
							New Part
						</Nav.Link> */}
          </Nav>
          <Nav>
            {auth ? (
              <Nav.Link href="/logout">Logout</Nav.Link>
            ) : (
              <Nav.Link href="/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
