export const theadData = [
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  {
    id: "company",
    label: "Company",
    minWidth: 200,
    align: "center",
  },
  {
    id: "file_status",
    label: "File Status",
    minWidth: 50,
    align: "center",
  },
  {
    id: "freight_type",
    label: "Freight Type",
    minWidth: 200,
    align: "center",
  },
  {
    id: "total_quantity",
    label: "Total Quantity",
    minWidth: 200,
    align: "center",
  },
  { id: "total_parts", label: "Part Count", minWidth: 200, align: "center" },
  { id: "created_at", label: "Created At", minWidth: 200, align: "center" },
];
