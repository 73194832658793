import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import TableRenderer from "../../util/tableRenderer";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import ProductionOrderItemsList from "./productionOrderItemsList";
import { productionOrderItemFilterSetup } from "../filters/config/production_order_items/productionOrderItemsSelection";
import { buttonStyleAddItems } from "../../styles/styleConfig";
import { theadDataPush } from "./productionOrderItemsConfig";
import axiosInstance from "../../hooks/usePostAxios";

export default function ProductionOrderItems() {
  const [filters, setFilters] = useState({
    requested_quantity_gt: "",
    requested_quantity_lt: "",
    production_quantity_gt: "",
    production_quantity_lt: "",
    original_quantity_gt: "",
    original_quantity_lt: "",
    sort: "",
    search: "",
    status: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const admin = localStorage.getItem("role");
  let { productionorder_id } = useParams();
  const { push } = useHistory();
  let theadData = theadDataPush(admin);
  const prodOrderItemsEndpoint = `esd/productionorders/${productionorder_id}/items/?ordering=${filters.sort}&requested_quantity__gt=${filters.requested_quantity_gt}&requested_quantity__lt=${filters.requested_quantity_lt}&production_quantity__gt=${filters.production_quantity_gt}&production_quantity__lt=${filters.production_quantity_lt}&original_quantity__gt=${filters.original_quantity_gt}&original_quantity__lt=${filters.original_quantity_lt}&search=${filters.search}&status=${filters.status}`;
  const prodOrderEndpoint = `esd/productionorders/${productionorder_id}`;

  const { loading, data, setUrl, setData } = useFetchAxios({
    endpoint: prodOrderItemsEndpoint,
  });
  const {
    loading: loadingProdOrder,
    data: dataProdOrder,
    setUrl: setUrlProdOrder,
  } = useFetchAxios({ endpoint: prodOrderEndpoint });

  useEffect(() => {
    const setData = () => {
      setUrlProdOrder(prodOrderEndpoint);
      setUrl(prodOrderItemsEndpoint);
    };
    setData();
  }, [prodOrderEndpoint, prodOrderItemsEndpoint, setUrl, setUrlProdOrder]);

  const redirect = () => {
    push({
      pathname: `/productionorder/${productionorder_id}/add`,
    });
  };

  const updateRequested = (id, requested_quantity, production_quantity) => {
    axiosInstance.patch(
      `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/items/${id}/`,
      {
        id: id,
        production_quantity: production_quantity,
        requested_quantity: requested_quantity,
      }
    );
  };

  const deleteRequested = (id) => {
    axiosInstance
      .delete(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/items/${id}/`
      )
      .then((res) => {
        let dataCopy = { ...data };
        let arr = dataCopy.results.filter((item, index) => item.id !== id);
        dataCopy.results = arr;
        setData(dataCopy);
      });
  };

  const deleteOrder = () => {
    axiosInstance
      .delete(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/`
      )
      .then(push("/productionorders"));
  };
  return (
    <>
      {loading || loadingProdOrder ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={data}
            header={theadData}
            component={ProductionOrderItemsList}
            title={`Production Order: ${dataProdOrder.name} | Total QTY: ${dataProdOrder.total_quantity}`}
            setUrl={setUrl}
            pagination={true}
            admin={admin}
            updateRequested={updateRequested}
            deleteRequested={deleteRequested}
            filter={true}
            filters={filters}
            setFilters={setFilters}
            filterSetup={productionOrderItemFilterSetup}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setData={setData}
            data2={{ ...data }}
          />
          {admin === "true" && (
            <>
              {dataProdOrder.status !== "Closed" && (
                <Button
                  className="m-2"
                  onClick={() => redirect()}
                  variant="outlined"
                  style={buttonStyleAddItems}
                  startIcon={<AddIcon />}
                >
                  Add More Items
                </Button>
              )}
              {data.count === 0 && (
                <Button
                  className="m-2"
                  onClick={() => deleteOrder()}
                  variant="outlined"
                  style={buttonStyleAddItems}
                  startIcon={<RemoveIcon />}
                >
                  Delete Order
                </Button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
