import React from "react";
import "../../css/basictable.css";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import InventoryLine from "./inventoryLine";

export default function InventoryItem({ data }) {
  const {
    name,
    total_quantity_inv_on_hand,
    total_quantity_inv_order,
    total_quantity_inv,
    inventory_part,
  } = data;
  return (
    <TableRow className="Cell">
      <TableCell align="center">{name}</TableCell>
      <TableCell align="center">
        {total_quantity_inv_on_hand ? total_quantity_inv_on_hand : 0}
      </TableCell>
      <TableCell align="center">
        {total_quantity_inv_order ? total_quantity_inv_order : 0}
      </TableCell>
      <TableCell align="center">
        {total_quantity_inv ? total_quantity_inv : 0}
      </TableCell>
      {inventory_part.inventory_line_inventory.map((data, idx) => {
        return <InventoryLine key={idx} data={data} />;
      })}
      {/* {inventory_part.length === 0 && <TableCell align="center">0</TableCell>} */}
      {/* {inventory_part.length === 0 && <TableCell align="center">0</TableCell>} */}
    </TableRow>
  );
}
