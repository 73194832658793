import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import ProductionOrdersList from "./productionOrdersList";
import TableRenderer from "../../util/tableRenderer";
import { productionOrderFilterSetup } from "../filters/config/production_order/productionOrderSelection";
import { theadData } from "./productionOrdersConfig";

export default function ProductionOrders() {
  let { status } = useParams();
  const admin = localStorage.getItem("role");
  const user = localStorage.getItem("user_id");
  const [showFilter, setShowFilter] = useState(false);

  const [filters, setFilters] = useState({
    status: "",
    customer: "",
    sort: "",
    search: "",
    total_quantity_lt: "",
    total_quantity_gt: "",
    total_parts_lt: "",
    total_parts_gt: "",
    file_status: "",
  });

  let load_url = status
    ? `esd/productionorders/?status=${status}`
    : `esd/productionorders/?ordering=${filters.sort}&customer=${filters.customer}&total_quantity_gt=${filters.total_quantity_gt}&total_quantity_lt=${filters.total_quantity_lt}&total_parts_gt=${filters.total_parts_gt}&total_parts_lt=${filters.total_parts_lt}&status=${filters.status}&file_status=${filters.file_status}&search=${filters.search}`;
  if (admin === "false") {
    load_url = `esd/productionorders/?user=${user}&status=O`;
  }

  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });
  useEffect(() => {
    const updateData = () => {
      setUrl(load_url);
    };
    updateData();
  }, [load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={ProductionOrdersList}
          title={"Production Orders"}
          setUrl={setUrl}
          pagination={true}
          admin={admin}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={productionOrderFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          hover={true}
        />
      )}
    </>
  );
}
