import { sort } from "./purchaseForecastConfig";

export const purchaseForecastFilterSetup = [
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  { label: "Search Part", value: "search", type: "text" },
];
