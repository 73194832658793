import React, { useEffect } from "react";
import axiosInstance from "../../hooks/axios";
import { useHistory } from "react-router-dom";

export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("role");
    localStorage.clear();
    axiosInstance.defaults.headers["Authorization"] = null;
    history.push("/login");
  });
  return <div>Logout</div>;
}
