import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton, TextField, FormControl } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

export default function ProductionOrderItemsList({ data, ...props }) {
  let { admin, updateRequested, deleteRequested } = props;
  const {
    id,
    status,
    part,
    requested_quantity,
    production_quantity,
    original_quantity,
  } = data;
  const [requested_quantity_state, setRequested_quantity_state] =
    useState(requested_quantity);
  const [production_quantity_state, setProduction_quantity_state] =
    useState(production_quantity);
  const [editable, setEditable] = useState(false);

  const updateRequestedAndEdit = () => {
    updateRequested(id, requested_quantity_state, production_quantity_state);
    updateRequested(id, requested_quantity_state, production_quantity_state);
    setEditable(false);
  };

  return (
    <TableRow style={{ fontSize: 20 }} align="center">
      <TableCell align="center">{part}</TableCell>
      <TableCell align="center">{status}</TableCell>
      <TableCell align="center">{original_quantity}</TableCell>

      {admin === "false" ? (
        <TableCell align="center">{requested_quantity_state}</TableCell>
      ) : (
        <TableCell align="center">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <TextField
              disabled={!editable}
              name={"requested_quantity"}
              label={"Requested Quantity"}
              type="number"
              variant="standard"
              onChange={(e) =>
                e.target.value < 0
                  ? setRequested_quantity_state(0)
                  : setRequested_quantity_state(e.target.value)
              }
              value={requested_quantity_state}
            />
          </FormControl>
        </TableCell>
      )}
      {admin === "false" ? (
        <TableCell align="center">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <TextField
              disabled={!editable}
              name={"Production_quantity"}
              label={"Production Quantity"}
              type="number"
              variant="standard"
              onChange={(e) =>
                e.target.value < 0
                  ? setProduction_quantity_state(0)
                  : setProduction_quantity_state(e.target.value)
              }
              value={production_quantity_state}
            />
          </FormControl>
        </TableCell>
      ) : (
        <TableCell align="center">{production_quantity_state}</TableCell>
      )}
      <TableCell align="center">
        {editable ? (
          <Tooltip title="Update">
            <IconButton onClick={() => updateRequestedAndEdit()}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => setEditable(true)}
              disabled={status === "C"}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* <IconButton
          onClick={() =>
            updateRequested(
              id,
              requested_quantity_state,
              production_quantity_state
            )
          }
        > */}
      </TableCell>
      {admin === "true" && (
        <TableCell align="center">
          <Tooltip title="Delete">
            <IconButton
              onClick={() => deleteRequested(id)}
              disabled={status === "C"}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
}
