import React, { useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import TableRenderer from "../../util/tableRenderer";
import SalesReportsItem from "./salesReportsItem";
import { salesReportFilterSetup } from "../filters/config/sales_report/salesReportSelection";
import { theadData } from "./salesReportsConfig";

export default function SalesReports() {
  const [filters, setFilters] = useState({
    sort: "",
    start_date: "",
    end_date: "",
  });
  const [showFilter, setShowFilter] = useState(false);

  const load_url = `esd/salesreports/?ordering=${filters.sort}&start_date=${filters.start_date}&end_date=${filters.end_date}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });

  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={SalesReportsItem}
          title={"Sales Reports"}
          setUrl={setUrl}
          pagination={true}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={salesReportFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          hover={true}
        />
      )}
    </>
  );
}
