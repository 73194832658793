import React from "react";

import { CSVLink } from "react-csv";
import { Button, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {
  buttonStyleAddPart,
  buttonStyleAddPartSuccess,
} from "../../styles/styleConfig";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
export default function PurchaseOrderItemToolbar({
  handleFileInput,
  uploadStatus,
  setFileType,
  loading,
  loadingPurchaseOrder,
  purchaseOrderName,
  data,
}) {
  const parseData = (data) => {
    debugger;
    let arr = [];
    data.forEach((item) => {
      !item["add"] && arr.push({ sku: item.part, quantity: item.quantity });
    });
    return arr;
  };
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <Box
            sx={{ m: 1, position: "relative" }}
            className="d-flex justify-content-between align-items-center"
          >
            <CSVLink
              className="text-decoration-none text-white"
              data={parseData(data.results)}
              filename={`PO_${purchaseOrderName}.csv`}
            >
              <Button
                id="submit"
                type="submit"
                variant="outlined"
                style={buttonStyleAddPart}
                endIcon={<DownloadIcon />}
              >
                CSV
              </Button>
            </CSVLink>
            <label htmlFor="upload-photo" className="d-flex align-items-center">
              <input
                accept=".csv,.xlsx,.pdf,.xls"
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={(e) => handleFileInput(e)}
              />
              <FormControl className="d-flex flex-row align-items-center">
                <FormLabel id="demo-radio-buttons-group-label" className="pr-3">
                  File Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="INV"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="INV"
                    control={<Radio />}
                    label="Invoice"
                    onChange={(e) => setFileType(e.target.value)}
                  />
                  <FormControlLabel
                    value="DST"
                    control={<Radio />}
                    label="Distribution Table"
                    onChange={(e) => setFileType(e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
              <Button
                style={
                  uploadStatus ? buttonStyleAddPartSuccess : buttonStyleAddPart
                }
                color="secondary"
                variant="contained"
                component="span"
                endIcon={<UploadFileIcon />}
              >
                {uploadStatus ? "Upload Again" : "Upload"}
              </Button>
            </label>
          </Box>
        </>
      )}
    </>
  );
}
