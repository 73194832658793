import { months, sort } from "./purchaseForecastConfig";

export const purchaseForecastFilterSetup = [
  {
    label: "Select Months",
    value: "months",
    type: "multi",
    selection: months,
  },
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  { label: "Search Part", value: "search", type: "text_change" },
  {
    label: "Suggested Amount GTE",
    value: "suggested_order_amount_gte",
    type: "text_change",
  },
];
