import React, { useState } from "react";
// import Fuse from "fuse.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import Alerts from "../../util/msgAlert";
import { buttonStyleAddPart } from "../../styles/styleConfig";

export default function AddPart({
  full_data,
  setData,
  orderAmount,
  setOrderAmount,
}) {
  const [part, setPart] = useState({
    add: false,
    id: 0,
    in_factory: 0,
    in_production_orders: 0,
    in_purchase_orders: 0,
    in_transit: 0,
    inventory: 0,
    name: "",
    new: false,
    sales_average_3_months: 0,
    sales_average_6_months: 0,
    sri_part: [],
    suggested_order_amount: 0,
    total_inventory: 0,
    total_sales: 0,
  });
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const load_url = `esd/parts-search/`;
  const { loading, data } = useFetchAxios({ endpoint: load_url });
  // useEffect(() => {
  //   const setPartData = () => {
  //     setUrl(load_url);
  //   };
  //   setPartData();
  // }, []);

  const setChange = (e) => {
    let copy = { ...part };
    copy["name"] = e.name;
    copy["id"] = e.id;

    setPart(copy);
  };
  const setQuantity = (e) => {
    if (typeof e === "object") {
      let copy = { ...part };
      copy["suggested_order_amount"] = parseInt(e.target.value);

      setPart(copy);
    } else {
      let copy = { ...part };
      copy["suggested_order_amount"] = parseInt(0);

      setPart(copy);
    }
  };

  const setFullChanges = () => {
    let copy = { ...full_data };
    copy.results.unshift(part);
    setOrderAmount(orderAmount + part["suggested_order_amount"]);
    setData(copy);
    setSuccess(true);
    setShow(false);
  };

  const showPartMenu = () => {
    setShow(true);
    setSuccess(false);
  };

  return (
    <div className="p-2 d-flex justify-content-center">
      {loading ? (
        <LinearProgress />
      ) : !show ? (
        <div>
          {success && (
            <Alerts
              className="text-center"
              severity="success"
              msg="Part Added"
            />
          )}
          <Button
            className="mb-2"
            onClick={() => showPartMenu()}
            style={buttonStyleAddPart}
            id="submit"
            type="submit"
            variant="outlined"
            endIcon={<AddIcon />}
          >
            Manual Part Add
          </Button>
        </div>
      ) : (
        <div
          style={{
            color: "hsl(0, 0%, 40%)",
            display: "inline-block",
            fontSize: 12,
            width: "10%",
            fontStyle: "italic",
            marginTop: "1em",
          }}
        >
          <div className="d-flex flex-column">
            <form onSubmit={() => setFullChanges()}>
              <Select options={data} onChange={(e) => setChange(e)} />
              <TextField
                id="standard-basic"
                label="Quantity"
                variant="standard"
                onChange={(e) =>
                  e.target.value < 0 ? setQuantity(0) : setQuantity(e)
                }
                className="p-2"
                type="number"
              />
              <Button
                className="mt-1"
                onClick={() => setFullChanges()}
                style={buttonStyleAddPart}
                id="submit"
                type="submit"
                variant="outlined"
                endIcon={<AddIcon />}
              >
                Add Part
              </Button>

              <Button
                className="mt-1"
                onClick={() => setShow(false)}
                style={buttonStyleAddPart}
                id="submit"
                type="submit"
                variant="outlined"
              >
                Hide
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
