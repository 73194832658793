import React, { useEffect, useState } from "react";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import PurchaseOrdersList from "./purchaseOrdersList";
import TableRenderer from "../../util/tableRenderer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { purchaseOrderFilterSetup } from "../filters/config/purchase_order/purchaseOrderSelection";
import { theadData } from "./purchaseOrderConfig";

export default function PurchaseOrdersProvider() {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    provider: "",
    created_at: "",
    search: "",
    total_parts_gte: "",
    total_parts_lte: "",
    total_quantity_gte: "",
    total_quantity_lte: "",
    sort: "",
    status: "",
  });
  const load_url = `esd/purchaseorders/?ordering=${filters.sort}&total_quantity_lte=${filters.total_quantity_lte}&total_quantity_gte=${filters.total_quantity_gte}&total_parts_gte=${filters.total_parts_gte}&total_parts_lte=${filters.total_parts_lte}&status=${filters.status}&search=${filters.search}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });
  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={PurchaseOrdersList}
          title={"Purchase Orders"}
          setUrl={setUrl}
          pagination={true}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={purchaseOrderFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          hover={true}
        />
      )}
    </>
  );
}
