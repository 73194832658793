import React, { useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import axiosInstance from "../../hooks/usePostAxios";

export default function NewPart() {
  const {
    REACT_APP_LOC_API,
    REACT_APP_LOCATE_URL,
    REACT_APP_SERVICE_ID,
    REACT_APP_TEMPLATE_ADD_ID,
    REACT_APP_USER_ID,
  } = process.env;
  const [data, setData] = useState({
    sku: "",
    confirmation: "",
    error: "",
    prevPart: "",
  });
  const { sku, confirmation, error, prevPart } = data;

  const postBackend = (res) => {
    let emailParam = {
      partNumber: sku,
    };

    let postObj = {
      id: res[0],
      name: sku,
      created_at: res[1],
    };
    axiosInstance
      .post("https://danielguardado.pythonanywhere.com/esd/parts/", postObj)
      .then(() => {
        setData({
          ...data,
          confirmation: "success",
          sku: "",
          prevPart: sku,
        });
        emailjs
          .send(
            REACT_APP_SERVICE_ID,
            REACT_APP_TEMPLATE_ADD_ID,
            emailParam,
            REACT_APP_USER_ID
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              setData({ ...data, error: error });
            }
          );
      });
  };

  async function postLocate() {
    const response = await axios
      .post(
        REACT_APP_LOCATE_URL,
        {
          parttype_id: "1",
          name: sku,
          number: sku,
        },
        {
          auth: {
            username: REACT_APP_LOC_API,
            password: REACT_APP_LOC_API,
          },
        }
      )
      .then((response) => {
        return [response.data.id, response.data.created_at];
      })
      .catch((error) => {
        setData({ ...data, confirmation: "error", sku: "" });
      });
    return response;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postLocate().then((res) => {
      postBackend(res);
    });
  };

  return error ? (
    <div>{error}</div>
  ) : (
    <div
      style={{ display: "flex", justifyContent: "center", borderRadius: "35" }}
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-group">
          {confirmation && (
            <div
              className={
                confirmation === "success"
                  ? "alert alert-primary"
                  : "alert alert-danger"
              }
              role="alert"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {confirmation === "success"
                ? `Part ${prevPart} was added:`
                : `Could not add part ${prevPart}`}
            </div>
          )}
          <label style={{ width: "250px", textAlign: "center" }}>SKU</label>
          <input
            style={{ width: "250px", textAlign: "center" }}
            type="text"
            className="form-control"
            placeholder="Enter SKU"
            onChange={(e) => setData({ ...data, sku: e.target.value })}
            value={sku}
          />
        </div>
        <button
          type="submit"
          className="btn btn-dark"
          style={{
            borderRadius: "35px",
            width: "250px",
            textAlign: "center",
          }}
        >
          Add Part
        </button>
      </form>
    </div>
  );
}
