import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

const Auth = ({ path, component: Component, exact }) => {
  const loggedIn = localStorage.getItem("isAuthenticated");
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        loggedIn ? (
          <Redirect to="/productionorders" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Protected = ({ path, component: Component, exact }) => {
  const loggedIn = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        role === "true" && loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/provider/part/totals" />
        )
      }
    />
  );
};

const Vendor = ({ path, component: Component, exact }) => {
  const loggedIn = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        role === "false" && loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
          // <Redirect to="/login" />
        )
      }
    />
  );
};

// const Vendor

export const AuthRoute = withRouter(Auth);
export const ProtectedRoute = withRouter(Protected);
export const ProtectedVendorRoute = withRouter(Vendor);
