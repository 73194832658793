export const sort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "total_quantity",
    label: "Total QTY - Ascending",
  },
  {
    value: "-total_quantity",
    label: "Total QTY - Descending",
  },
];
