export const sort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "date",
    label: "Date - Ascending",
  },
  {
    value: "-date",
    label: "Date - Descending",
  },
];
