import React, { useEffect, useState } from "react";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import PurchaseOrderItemList from "./purchaseOrderItemList";
import { purchaseOrderItemFilterSetup } from "../filters/config/purchase_order_items/purchaseOrderItemSelection";
import StatusChange from "./statusChange";
import Alert from "@mui/material/Alert";
import axiosInstance from "../../hooks/usePostAxios";
import PurchaseOrderItemToolbar from "./purchaseOrderItemToolbar";
import PurchaseOrderFiles from "./purchaseOrderFiles";

export default function PurchaseOrderItems() {
  const {
    REACT_APP_EV_API_KEY,
    REACT_APP_EV_ACCESS_TOKEN,
    REACT_APP_EV_BASE_URL,
  } = process.env;
  const admin = localStorage.getItem("role");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [fileType, setFileType] = useState("INV");
  const [file, setFile] = useState(null);
  const [filters, setFilters] = useState({
    part: "",
    quantity_gt: "",
    quantity_lt: "",
    sort: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  let { purchaseorder_id } = useParams();
  const theadData = [
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    { id: "quantity", label: "Quantity", minWidth: 200, align: "center" },
  ];
  const load_url = `esd/purchaseorders/${purchaseorder_id}/items/?part__name__icontains=${filters.part}&quantity__gt=${filters.quantity_gt}&quantity__lt=${filters.quantity_lt}&ordering=${filters.sort}`;
  const load_url2 = `esd/purchaseorders/${purchaseorder_id}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });
  const {
    loading: loadingPurchaseOrder,
    data: dataPurchaseOrder,
    setUrl: setUrlPurchaseOrder,
  } = useFetchAxios({ endpoint: load_url2 });

  useEffect(() => {
    const setData = () => {
      setUrlPurchaseOrder(load_url2);
      setUrl(load_url);
    };
    setData();
  }, [load_url, load_url2, setUrl, setUrlPurchaseOrder]);

  const handleFileInput = (e) => {
    setUploadStatus(null);
    setFile(e.target.files[0]);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    let originalFileName = e.target.files[0].name;
    let split = originalFileName.split(".");
    let fileEnding = split[split.length - 1];
    if (
      fileEnding === "csv" ||
      fileEnding === "xlsx" ||
      fileEnding === "xls" ||
      fileEnding === "pdf"
    ) {
      let fileName = `${
        fileType + "_" + dataPurchaseOrder.name.split("/").join("_")
      }.${fileEnding}`;
      data.append("path", `esd_app/invoice_upload/${fileName}`);
      axiosInstance
        .post(`${REACT_APP_EV_BASE_URL}resources/upload`, data, {
          headers: {
            "ev-api-key": REACT_APP_EV_API_KEY,
            "ev-access-token": REACT_APP_EV_ACCESS_TOKEN,
          },
        })
        .then((res) => {
          setUploadStatus(true);
          axiosInstance.post(
            "https://danielguardado.pythonanywhere.com/esd/files/",
            {
              name: fileName,
              type: fileType,
              status: "P",
              purchaseorder: purchaseorder_id,
              original_filename: originalFileName,
            }
          );
        })
        .catch((error) => {
          setUploadStatus(false);
          setUploadError(error);
        });
    } else {
      setUploadStatus(false);
      setUploadError("Wrong file type");
    }
  };

  return (
    <>
      {loading || loadingPurchaseOrder ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={data}
            header={theadData}
            component={PurchaseOrderItemList}
            title={`Purchase Order: ${dataPurchaseOrder.name} | Freight: ${dataPurchaseOrder.freight_type}`}
            setUrl={setUrl}
            pagination={true}
            filter={true}
            filters={filters}
            setFilters={setFilters}
            filterSetup={purchaseOrderItemFilterSetup}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
          {uploadStatus === true && (
            <Alert className="d-flex justify-content-center">
              {file.name} was successfully uploaded.
            </Alert>
          )}
          {uploadStatus === false && (
            <Alert severity="error" className="d-flex justify-content-center">
              File was not successfully uploaded, Error: {uploadError}
            </Alert>
          )}
          <PurchaseOrderItemToolbar
            handleFileInput={handleFileInput}
            uploadStatus={uploadStatus}
            setFileType={setFileType}
            loading={loading}
            loadingPurchaseOrder={loadingPurchaseOrder}
            data={data}
            purchaseOrderName={dataPurchaseOrder.name}
          />
          {purchaseorder_id && (
            <PurchaseOrderFiles purchaseorder_id={purchaseorder_id} />
          )}

          {admin === "true" && (
            <div className="d-flex justify-content-center flex-column align-items-end pt-3 pb-3 pr-3 border-bottom">
              <StatusChange
                parentStatus={dataPurchaseOrder.status}
                name={dataPurchaseOrder.name}
                customer={dataPurchaseOrder.customer}
                freightType={dataPurchaseOrder.freight_type}
                purchaseorder_id={purchaseorder_id}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
