import React from "react";
import { buttonStyle } from "../../styles/styleConfig";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Tooltip from "@mui/material/Tooltip";

export default function PaginationFooter({ urlprevius, urlnext, setData }) {
  return (
    <>
      <Tooltip title="Previous">
        <IconButton
          disabled={!urlprevius ? true : false}
          onClick={() => setData(urlprevius)}
          style={buttonStyle}
          variant="outlined"
        >
          <NavigateBeforeIcon hidden={!urlprevius ? true : false} />
        </IconButton>
      </Tooltip>

      <div>
        <Tooltip title="Next">
          <IconButton
            className="me-4"
            disabled={!urlnext ? true : false}
            onClick={() => setData(urlnext)}
            style={buttonStyle}
            variant="outlined"
          >
            <NavigateNextIcon hidden={urlnext ? false : false} />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}
