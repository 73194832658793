import React from "react";
import { buttonStyleDone } from "../../styles/styleConfig";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";
import { Box } from "@mui/system";
export default function ProdFooterNav({ posting, submit }) {
  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Button
        onClick={() => submit()}
        style={buttonStyleDone}
        id="submit"
        type="submit"
        variant="outlined"
        endIcon={<DoneIcon />}
      >
        Done
      </Button>

      {posting && (
        <CircularProgress
          size={24}
          sx={{
            color: blue[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
}
