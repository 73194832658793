import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";
export default function PurchaseOrderItemList({ data }) {
  const { part, quantity } = data;
  return (
    <TableRow>
      <TableCell align="center">{part}</TableCell>
      <TableCell align="center">{quantity}</TableCell>
    </TableRow>
  );
}
