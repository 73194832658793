import React, { useEffect, useState } from "react";
import PartTotalItem from "./partTotalItemProvider";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import axiosInstance from "../../hooks/usePostAxios";
import { partFilterSetup } from "../filters/config/part_totals/partTotalsSelection";

export default function PartTotal() {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "",
    requested_quantity_gt: "",
    requested_quantity_lt: "",
    production_quantity_gt: "",
    production_quantity_lt: "",
    balance_gt: "",
    balance_lt: "",
  });
  const theadData = [
    { id: "part", label: "Part", minWidth: 50, align: "center" },
    {
      id: "requested_quantity",
      label: "Requested",
      minWidth: 20,
      align: "right",
    },
    {
      id: "copy_quantity",
      label: "Add All",
      minWidth: 20,
      align: "center",
    },
    {
      id: "produced_quantity",
      label: "Produced Quantity",
      minWidth: 50,
      align: "left",
    },
    {
      id: "balance",
      label: "Balance",
      minWidth: 50,
      align: "center",
    },
    { id: "submit_edit", label: "Submit/Edit", minWidth: 50, align: "center" },
  ];

  const load_url = `esd/parttotals/?search=${filters.search}&ordering=${filters.sort}&requested_quantity_gt=${filters.requested_quantity_gt}&requested_quantity_lt=${filters.requested_quantity_lt}&production_quantity_gt=${filters.production_quantity_gt}&production_quantity_lt=${filters.production_quantity_lt}&balance_gt=${filters.balance_gt}&balance_lt=${filters.balance_lt}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });

  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  const postItems = (arr) => {
    arr.forEach((item) => {
      axiosInstance.patch(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${item.production_order}/items/${item.production_order_item}/`,
        {
          requested_quantity: item.requested_quantity,
          production_quantity: item.production_quantity,
        }
      );
    });
  };

  const updateAPI = (obj, quantity) => {
    const send_arr = [];
    obj.forEach((item) => {
      if (quantity > item.requested_quantity) {
        send_arr.push({
          production_quantity: item.requested_quantity,
          requested_quantity: item.requested_quantity,
          production_order: item.productionorder,
          production_order_item: item.id,
        });
        quantity -= item.requested_quantity;
      } else if (quantity > 0) {
        send_arr.push({
          production_quantity: quantity,
          requested_quantity: item.requested_quantity,
          production_order: item.productionorder,
          production_order_item: item.id,
        });
        quantity -= item.requested_quantity;
      } else {
        send_arr.push({
          production_quantity: 0,
          requested_quantity: item.requested_quantity,
          production_order: item.productionorder,
          production_order_item: item.id,
        });
        quantity -= item.requested_quantity;
      }
    });
    if (quantity > 0) {
      send_arr[0].production_quantity =
        quantity + send_arr[0].production_quantity;
    }
    postItems(send_arr);
    return;
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={PartTotalItem}
          title={"Requested Part Totals"}
          setUrl={setUrl}
          pagination={true}
          updateAPI={updateAPI}
          maxHeight={700}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={partFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      )}
    </>
  );
}
