import React from "react";
import NavbarAdmin from "./navbarAdmin";
import NavbarProvider from "./navbarProvider";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import "./navbar.css";

export default function Navbar() {
  const load_url = `esd/customers/all`;
  const { loading, data: customers } = useFetchAxios({ endpoint: load_url });
  const role = localStorage.getItem("role");
  const location = window.location.href.split("/")[3].toLowerCase();
  const auth = localStorage.getItem("access_token");

  return (
    <>
      {role === "true" ? (
        <NavbarAdmin
          location={location}
          auth={auth}
          customers={customers}
          loading={loading}
        />
      ) : (
        <NavbarProvider />
      )}
    </>
  );
}
