import React from "react";
import { MenuItem, TextField, Typography } from "@mui/material";

export default function FilterItem({
  item,
  filters,
  setFilters,
  filterClone,
  setFilterClone,
}) {
  // const [search, setSearch] = useState("");

  const handleTextChange = (e) => {
    e.preventDefault();
    let { value, name } = e.target;
    let copy = { ...filterClone };
    copy[name] = value;
    // setSearch(value);
    setFilterClone(copy);
    setFilters(copy);
  };
  const handleChange = (e) => {
    e.preventDefault();
    let { value, name } = e.target;
    let copy = { ...filters };
    copy[name] = value;
    setFilters(copy);
  };
  // const handleSubmit = (e) => {
  //   let copy = { ...filters };
  //   copy[item.value] = search;
  //   setFilters(copy);
  // };
  const handleAll = (e) => {
    // setSearch(e.target.value);
    let copy = { ...filterClone };
    copy[item.value] = e.target.value;
    setFilterClone(copy);
  };
  const handleRangeBox = (e, singleItem) => {
    // setSearch(e.target.value);
    let copy = { ...filterClone };
    copy[singleItem.value] = e.target.value;
    setFilterClone(copy);
  };
  const selectForm = () => {
    if (item.type === "multi") {
      return (
        <TextField
          sx={{ m: 1, width: 140, minWidth: 120 }}
          id="outlined-select-currency"
          select
          name={item.value}
          label={item.label}
          value={filters[item.value]}
          onChange={handleChange}
          variant="standard"
        >
          {item.selection.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    } else if (item.type === "text") {
      return (
        <TextField
          sx={{ m: 1, width: 140, minWidth: 120 }}
          id="outlined-select-currency"
          name={item.value}
          label={item.label}
          value={filterClone[item.value]}
          onChange={(e) => handleAll(e)}
          variant="standard"
        ></TextField>
      );
    } else if (item.type === "text_change") {
      return (
        <>
          <TextField
            sx={{ m: 1, width: 140, minWidth: 120 }}
            id="outlined-select-currency"
            name={item.value}
            label={item.label}
            value={filterClone[item.value]}
            onChange={(e) => handleTextChange(e)}
            variant="standard"
          ></TextField>
        </>
      );
    } else if (item.type === "range") {
      return (
        <div className="d-flex align-items-end">
          <Typography className="border-left pl-2" variant="subtitle1">
            {item.title}
          </Typography>
          <TextField
            className="ml-2 mr-2"
            sx={{ width: 50 }}
            id="outlined-select-currency"
            name={item.set[0].value}
            label={item.set[0].label}
            value={filterClone[item.set[0].value]}
            onChange={(e) => handleRangeBox(e, item.set[0])}
            variant="standard"
            type="number"
            size="small"
          ></TextField>
          <div>-</div>
          <TextField
            sx={{ width: 50 }}
            className="ml-2 mr-2"
            id="outlined-select-currency"
            name={item.set[1].value}
            label={item.set[1].label}
            value={filterClone[item.set[1].value]}
            onChange={(e) => handleRangeBox(e, item.set[1])}
            type="number"
            variant="standard"
            size="small"
          ></TextField>
        </div>
      );
    }
  };

  return <>{selectForm()}</>;
}
