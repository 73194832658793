import { TableCell } from "@material-ui/core";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { TableRow, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import React from "react";
export default function POdata({ data, idx, entireData, ...props }) {
  const { setData, counter, setCounter } = props;
  const handleDelete = () => {
    const new_obj = { ...entireData };

    new_obj["results"][idx]["add"] = true;
    let newCount = counter;
    setCounter((newCount -= new_obj["results"][idx]["quantity"]));
    new_obj["results"][idx]["quantity"] = 0;
    setData(new_obj);
  };
  return (
    !data.add && (
      <TableRow className="Cell">
        <TableCell align="center">{data.name}</TableCell>
        <TableCell align="center">{data.quantity}</TableCell>
        <TableCell align="center">
          <Tooltip title="Remove">
            <IconButton onClick={() => handleDelete()}>
              <RemoveShoppingCartIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  );
}
