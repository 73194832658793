import { sort } from "./inventoryConfig";

export const inventoryFilterSetup = [
  // {
  //   label: "Select Site",
  //   value: "sites",
  //   type: "multi",
  //   selection: sites,
  // },
  {
    label: "Search Part",
    value: "search",
    type: "text",
  },
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  {
    type: "range",
    title: "Total QTY on Hand:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_inv_on_hand_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_inv_on_hand_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Total QTY on Order:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_inv_order_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_inv_order_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Total QTY:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_inv_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_inv_lt",
        type: "text",
      },
    ],
  },
];
