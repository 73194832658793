import React from "react";
import "../../css/basictable.css";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function PartsItem({ data }) {
	const { name, id } = data;
	return (
		<TableRow className="Cell">
			<TableCell align="center">{name}</TableCell>
			<TableCell align="center">{id}</TableCell>
		</TableRow>
	);
}
