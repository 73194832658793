import React, { useState } from "react";
import axiosInstance from "../../hooks/usePostAxios";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import {
  Button,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { buttonStyleContinue } from "../../styles/styleConfig";

export default function StatusChange({
  parentStatus,
  name,
  customer,
  freightType,
  purchaseorder_id,
}) {
  const [status, setStatus] = useState(parentStatus);
  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const putRequest = (e) => {
    e.preventDefault();
    axiosInstance
      .put(
        `https://danielguardado.pythonanywhere.com/esd/purchaseorders/${purchaseorder_id}/`,
        {
          customer: customer,
          name: name,
          freight_type: freightType,
          status: status,
        }
      )
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {show && (
        <Alert severity="success" className="mb-3">
          Status Updated!
        </Alert>
      )}
      {!showStatus ? (
        <Tooltip title="expand">
          <IconButton onClick={() => setShowStatus(true)}>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="collapse">
          <IconButton onClick={() => setShowStatus(false)}>
            <ArrowDropUpIcon />
          </IconButton>
        </Tooltip>
      )}
      {showStatus && (
        <>
          <label className="text-center">Invoiced in locate Status</label>

          <form className="pt-3" onSubmit={(e) => putRequest(e)}>
            <FormControl style={{ minWidth: 180 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="months"
                id="months"
                value={status}
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={"P"}>Pending</MenuItem>
                <MenuItem value={"C"}>Completed</MenuItem>
              </Select>
              <Button
                className="mt-3"
                style={buttonStyleContinue}
                id="submit"
                type="submit"
                variant="outlined"
              >
                Update Status
              </Button>
            </FormControl>
          </form>
        </>
      )}
    </>
  );
}
