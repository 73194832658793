import React, { useEffect, useState } from "react";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import InventoryItem from "./inventoryList";
import { inventoryFilterSetup } from "../filters/config/inventory/inventorySelection";

export default function Inventory() {
  const [filters, setFilters] = useState({
    search: "",
    sort: "",
    total_quantity_inv_gt: "",
    total_quantity_inv_lt: "",
    total_quantity_inv_order_gt: "",
    total_quantity_inv_order_lt: "",
    total_quantity_inv_on_hand_gt: "",
    total_quantity_inv_on_hand_lt: "",
  });
  let load_url = `esd/inventory/?ordering=${filters.sort}&search=${filters.search}&total_quantity_inv_on_hand_gt=${filters.total_quantity_inv_on_hand_gt}&total_quantity_inv_on_hand_lt=${filters.total_quantity_inv_on_hand_lt}&total_quantity_inv_order_gt=${filters.total_quantity_inv_order_gt}&total_quantity_inv_order_lt=${filters.total_quantity_inv_order_lt}&total_quantity_inv_gt=${filters.total_quantity_inv_gt}&total_quantity_inv_lt=${filters.total_quantity_inv_lt}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });
  const [showFilter, setShowFilter] = useState(false);
  const theadData = [
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    {
      id: "total_quantity_inv_on_hand",
      label: "Total Quantity on Hand",
      minWidth: 200,
      align: "center",
    },
    {
      id: "total_quantity_inv_order",
      label: "Total Quantity on Order",
      minWidth: 200,
      align: "center",
    },
    {
      id: "total_quantity_inv",
      label: "Total Quantity",
      minWidth: 200,
      align: "center",
    },
    { id: "main", label: "Main", minWidth: 200, align: "center" },
    {
      id: "vendor",
      label: "Vendor",
      minWidth: 200,
      align: "center",
    },
  ];

  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [setUrl, load_url]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={InventoryItem}
          title={"Inventory"}
          setUrl={setUrl}
          pagination={true}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={inventoryFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      )}
    </>
  );
}
