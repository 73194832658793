import React from "react";
import POItem from "./purchaseOrderNewItem";
import { CSVLink } from "react-csv";
import {
  buttonStyleCreate,
  buttonStyleCreateLoading,
} from "../../styles/styleConfig";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/Download";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
export default function PurchaseOrderCreate({
  data,
  setUrl,
  setPostData,
  postData,
  setOrderButton,
  createPostandUpdate,
  loading,
  loadingOrder,
  setData,
  counter,
  setCounter,
  freightType,
  setFreightType,
}) {
  const theadData = [
    { id: "part", label: "Part", minWidth: 100, align: "center" },
    {
      id: "quantity",
      label: "Quantity",
      minWidth: 100,
      align: "center",
    },
    {
      id: "delete",
      label: "Delete",
      minWidth: 100,
      align: "center",
    },
  ];

  const parseData = (data) => {
    let arr = [];
    data.results.forEach((item) => {
      !item["add"] && arr.push({ sku: item.name, quantity: item.quantity });
    });
    return arr;
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={data}
            entireData={data}
            header={theadData}
            component={POItem}
            title={`Purchase Order Cart | Purchase Order Total Quantity: ${counter}/7500`}
            setUrl={setUrl}
            setPostData={setPostData}
            postData={postData}
            setData={setData}
            counter={counter}
            setCounter={setCounter}
          />
          <div className="d-flex justify-content-between p-2">
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                style={buttonStyleCreate}
                id="submit"
                type="submit"
                variant="outlined"
                onClick={() => setOrderButton(false)}
                startIcon={<NavigateBeforeIcon />}
              >
                Back To Orders
              </Button>
            </Box>
            <Checkbox
              icon={<AirplanemodeInactiveIcon />}
              checkedIcon={<AirplanemodeActiveIcon />}
              checked={freightType}
              onChange={() => setFreightType(!freightType)}
            />

            <Box sx={{ m: 1, position: "relative" }}>
              {/* <CSVLink
                className="text-decoration-none text-white"
                data={parseData(data)}
                > */}
              <Button
                onClick={() => createPostandUpdate()}
                style={
                  loadingOrder ? buttonStyleCreateLoading : buttonStyleCreate
                }
                id="submit"
                type="submit"
                variant="outlined"
                disabled={loadingOrder}
                endIcon={<DownloadIcon />}
              >
                Create Purchase Order
              </Button>
              {/* </CSVLink> */}

              {loadingOrder && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: blue[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
}
