export const productionOrderItemsSort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "requested_quantity",
    label: "Requested QTY - Ascending",
  },
  {
    value: "-requested_quantity",
    label: "Requested QTY - Descending",
  },
  {
    value: "production_quantity",
    label: "Production QTY - Ascending",
  },
  {
    value: "-production_quantity",
    label: "Production QTY - Descending",
  },
  {
    value: "original_quantity",
    label: "Original QTY - Ascending",
  },
  {
    value: "-original_quantity",
    label: "Original QTY  - Descending",
  },
];
export const status = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "P",
    label: "Pending",
  },
  {
    value: "F",
    label: "Fulfilled",
  },
  {
    value: "C",
    label: "Closed",
  },
];
