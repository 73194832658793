import React, { useEffect, useState } from "react";
import useFetchAxios from "../../hooks/useFetchAxios7";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import ForecastItem from "./forecastItem";
import { purchaseForecastFilterSetup } from "../filters/config/purchaseForecastReport/purchaseForecastSelection";
import { CSVLink } from "react-csv";
import { addMonths } from "../../util/forecast_months";
import { Button, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { buttonStyleAddPart } from "../../styles/styleConfig";
import { monthNamesFunc } from "../../util/monthNames";

export default function PurchaseForecast() {
  const [showFilter, setShowFilter] = useState(false);

  const [filters, setFilters] = useState({
    search: "",
    sort: "",
  });

  let load_url = `esd/purchaseforecast/?&search=${filters.search}&ordering=${filters.sort}`;

  const { loading, data, setUrl, setData } = useFetchAxios({
    endpoint: load_url,
  });

  const [headers, setHeaders] = useState([]);
  const [monthsArr, setMonthsArr] = useState([]);

  const parseData = (data) => {
    let arr = [];
    data.forEach((item) => {
      let copy = { ...item };
      copy.sri_part.forEach((item2) => {
        copy[item2.date] = item2.total_quantity;
      });
      delete copy["new"];
      delete copy["add"];
      delete copy["sri_part"];
      arr.push(copy);
    });
    return arr;
  };

  useEffect(() => {
    const getHeaders = () => {
      let theadData = [
        { id: "part", label: "Part", minWidth: 50, align: "center" },
      ];
      const months = [];
      const monthNames = monthNamesFunc();
      const today = new Date(); /*from ww w .jav a  2s.  co m*/
      let d;
      let month;
      for (let i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        theadData.push({
          id: month,
          label: month,
          minWidth: 50,
          align: "center",
        });
        months.push(month);
      }

      theadData.push({
        id: "total_sales",
        label: "Total Sales",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "sales_avg",
        label: "6 Month AVG",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_quantity_inv_on_hand",
        label: "Inventory",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_quantity_inv_order",
        label: "In Transit",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "in_factory",
        label: "In Factory",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_inventory",
        label: "Total Inventory",
        minWidth: 50,
        align: "center",
      });

      setHeaders(theadData);
      setMonthsArr(months);
    };
    const setData = () => {
      setUrl(load_url);
      getHeaders();
    };
    setData();
  }, [filters.sort, load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={data}
            header={headers}
            component={ForecastItem}
            title={"Purchase Forecast Report"}
            setUrl={setUrl}
            pagination={true}
            months={monthsArr}
            filter={true}
            filters={filters}
            setFilters={setFilters}
            filterSetup={purchaseForecastFilterSetup}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setData={setData}
            data2={data}
          />
          <Box sx={{ m: 1, position: "relative" }}>
            <CSVLink
              className="text-decoration-none text-white"
              data={parseData(data.results)}
            >
              <Button
                id="submit"
                type="submit"
                variant="outlined"
                style={buttonStyleAddPart}
                endIcon={<DownloadIcon />}
              >
                CSV
              </Button>
            </CSVLink>
          </Box>
        </>
      )}
    </>
  );
}
