import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PartReportForm from "../../util/PartReportForm";
// import PartReport from "../part_report/PartReport";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import PurchaseForecast from "../purchase_forecast/purchaseForecast.jsx";

export default function ProductionOrderItemsAdd({ ...props }) {
  let { location } = props;
  let { productionorder_id } = useParams();
  const [months, setMonths] = useState(3);
  const [submitForm, setSubmitForm] = useState(false);
  const formSubmit = () => setSubmitForm(true);
  const load_url = `esd/productionorders/${productionorder_id}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });
  useEffect(() => {
    setUrl(load_url);
    const setData = () => {
      if ("props" in location) {
        setMonths(location.props.months);
        setSubmitForm(true);
      }
    };
    setData();
  }, [location, load_url, setUrl]);
  return (
    <>
      {submitForm && !loading ? (
        <PurchaseForecast
          blockDone={true}
          productionorder_id={productionorder_id}
          months={months}
          setMonths={setMonths}
          doneButton={true}
          customer={data.customer}
        />
      ) : (
        <PartReportForm setMonths={setMonths} submitForm={formSubmit} />
      )}
    </>
  );
}
