import React, { useEffect, useState } from "react";
import useFetchAxios from "../../hooks/useFetchAxios6";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import PurchaseForecastItem from "./purchaseForecastItem";
import axios from "axios";
import { useHistory } from "react-router";
import { monthNamesFunc } from "./monthNames";
import { purchaseForecastFilterSetup } from "../filters/config/purchaseForecast/purchaseForecastSelection";
// import { inventoryFilterSetup } from "../filters/config/inventory/inventorySelection";
import axiosInstance from "../../hooks/usePostAxios";
import AddPart from "./new_part";

export default function PurchaseForecast({
  months = 3,
  productionorder_id,
  setMonths,
  blockDone,
  doneButton,
  customer,
}) {
  const [showFilter, setShowFilter] = useState(false);
  const [posting, setPosting] = useState(false);
  const [orderAmount, setOrderAmount] = useState(0);
  const [data2, setData2] = useState("");

  const [filters, setFilters] = useState({
    months: months,
    suggested_order_amount_gte: 0,
    search: "",
    sort: "",
  });
  let load_url;
  if (blockDone) {
    load_url = `esd/purchaseforecast/?months=${filters.months}&ordering=${filters.sort}&suggested_order_amount_gte=0&customer=${customer}`;
  } else {
    load_url = `esd/purchaseforecast/?months=${filters.months}&search=${filters.search}`;
  }
  const { loading, data, setUrl, setData } = useFetchAxios({
    endpoint: load_url,
  });
  const load_url2 = `esd/productionorders/${productionorder_id}`;
  const {
    loading: loadingProdOrder,
    data: dataProdOrder,
    setUrl: setUrlProdOrder,
    // setData: setDataProdOrder,
  } = useFetchAxios({
    endpoint: load_url2,
  });
  // const load_url = `esd/purchaseforecast/?months=${filters.months}&search=${filters.search}`;
  // const productionOrderData = useFetchAxios({ endpoint: load_url2 });
  // prodOrder

  const [headers, setHeaders] = useState([]);
  const [monthsArr, setMonthsArr] = useState([]);
  const { push } = useHistory();
  const commitAll = (data2) => {
    // let a = orderAmount;
    let selected = [];
    data2.results.forEach((ele) => selected.push(ele.id));

    let copy = { ...data };
    let max = orderAmount;
    // let sum = orderAmount
    for (const property in copy.results) {
      if (selected.includes(copy.results[property]["id"])) {
        copy.results[property]["add"] = false;
        max += copy.results[property]["suggested_order_amount"];
      }
    }
    setOrderAmount(max);
    // setMax(sum)
    setData(copy);
  };
  const submit = () => {
    let copy = { ...data };
    let newArr = [];
    for (const property in copy.results) {
      if (copy.results[property]["add"] === false) {
        newArr.push({
          part_id: copy.results[property]["id"],
          requested_quantity: copy.results[property]["suggested_order_amount"],
        });
      }
    }
    setPosting(true);
    axiosInstance
      .post(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/items/`,
        newArr
      )
      .then((response) => {
        setPosting(false);
        push(`/productionorder/${productionorder_id}/items`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uncommitAll = () => {
    let copy = { ...data };
    // let sum = orderAmount

    for (const property in copy.results) {
      copy.results[property]["add"] = true;
      // sum -= copy.results[property]['suggested_order_amount']
    }
    setOrderAmount(0);
    setData(copy);
  };

  useEffect(() => {
    const getHeaders = () => {
      const theadData = [
        { id: "part", label: "Part", minWidth: 50, align: "center" },
      ];

      const months = [];
      const monthNames = monthNamesFunc();
      const today = new Date();
      let d;
      let month;
      for (let i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        theadData.push({
          id: month,
          label: month,
          minWidth: 50,
          align: "center",
        });
        months.push(month);
      }
      theadData.push({
        id: "total_sales",
        label: "Total Sales",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "sales_avg",
        label: "6 Month AVG",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_quantity_inv_on_hand",
        label: "Inventory",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_quantity_inv_order",
        label: "In Transit",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "in_factory",
        label: "In Factory",
        minWidth: 50,
        align: "center",
      });
      theadData.push({
        id: "total_inventory",
        label: "Total Inventory",
        minWidth: 50,
        align: "center",
      });

      productionorder_id &&
        theadData.push({
          id: "order_amount",
          label: "Order Amount",
          minWidth: 50,
          align: "center",
        });
      productionorder_id &&
        theadData.push({
          id: "submit_pf",
          label: "Submit",
          minWidth: 50,
          align: "center",
        });
      setHeaders(theadData);
      setMonthsArr(months);
    };
    const setData = () => {
      setUrl(load_url);
      setUrlProdOrder(load_url2);

      getHeaders();
    };
    setData();
  }, [
    filters.months,
    filters.sort,
    setUrlProdOrder,
    setUrl,
    productionorder_id,
    load_url,
    load_url2,
  ]);
  useEffect(() => {
    const filterThis = () => {
      let data2;
      if (!loading) {
        data2 = { ...data };
        if (filters.suggested_order_amount_gte > 0) {
          data2.results = data2.results.filter(
            (obj) =>
              obj.suggested_order_amount >= filters.suggested_order_amount_gte
          );
          setData2(data2);
        } else if (filters.search !== "") {
          data2.results = data2.results.filter((obj) =>
            obj.name.includes(filters.search.toUpperCase())
          );
          setData2(data2);
        }
      }
    };
    filterThis();
  }, [filters.suggested_order_amount_gte, filters.search, data, loading]);
  // useEffect(() => {
  //   const setData = () => {
  //     let sum = orderAmount
  //     if (data){
  //       for (const property in data.results) {
  //         if (data.results[property]['add'] === false){

  //           sum += data.results[property]['suggested_order_amount']
  //         } else if (data.results[property]['add'] === true){
  //           sum -= data.results[property]['suggested_order_amount']
  //         }
  //       }
  //       setOrderAmount(sum)
  //     }

  //   };
  //   setData();
  // }, [data]);

  const redirect = () => {
    push({
      pathname: `/productionorder/${productionorder_id}/items`,
    });
  };

  const postPart = (part_id, requested_quantity, setIdDelete) => {
    // TODO Change this it's bad practice to use components like this only should have 1 way of data transfer
    // !used payload for future iterations
    // const payload = {
    //   data: postData,
    // };
    let postData = {
      part_id: part_id,
      requested_quantity: requested_quantity,
    };
    axios
      .post(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/items/`,
        postData
      )
      .then((res) => {
        setIdDelete(res.data.id);
      })
      .catch((err) => console.log(err));
  };

  const deletePart = (id) => {
    axios.delete(
      `https://danielguardado.pythonanywhere.com/esd/productionorders/${productionorder_id}/items/${id}`
    );
  };
  // let data2;
  // if (!loading) {
  //   data2 = data;
  //   if (filters.suggested_order_amount_gte > 0) {
  //     data2.results = data2.results.filter(
  //       (obj) =>
  //         obj.suggested_order_amount >= filters.suggested_order_amount_gte
  //     );
  //     setData(data2);
  //   } else if (filters.search !== "") {
  //     data2 = data2.filter((obj) =>
  //       obj.name.includes(filters.search.toUpperCase())
  //     );
  //   }
  // }
  return (
    <>
      {loading || loadingProdOrder ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={
              filters.suggested_order_amount_gte === "" ||
              filters.suggested_order_amount_gte === 0
                ? data
                : data2
            }
            header={headers}
            component={PurchaseForecastItem}
            title={
              productionorder_id
                ? `Purchase Forecast | Current Order Amount: ${orderAmount} | Current Vendor: ${dataProdOrder.customer_company}`
                : "Purchase Forecast Detail"
            }
            setUrl={setUrl}
            pagination={true}
            months={monthsArr}
            filter={true}
            filters={filters}
            setFilters={setFilters}
            filterSetup={purchaseForecastFilterSetup}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            postPart={postPart}
            deletePart={deletePart}
            redirect={redirect}
            setMonths={setMonths}
            productionorder_id={productionorder_id}
            blockDone={blockDone}
            doneButton={doneButton}
            setData={setData}
            data2={data}
            setOrderAmount={setOrderAmount}
            orderAmount={orderAmount}
            submit={submit}
            posting={posting}
            commitAll={commitAll}
            uncommitAll={uncommitAll}
          />
          <AddPart
            setData={setData}
            full_data={data}
            orderAmount={orderAmount}
            setOrderAmount={setOrderAmount}
          />
        </>
      )}
    </>
  );
}
