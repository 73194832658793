import {
  productionOrdersSort,
  providers,
  purchaseOrderStatus,
  purchaseOrdersFileStatus,
} from "./purchaseOrderConfig";

export const purchaseOrderFilterSetup = [
  { label: "Search Name", value: "search", type: "text" },

  {
    label: "Select Status",
    value: "status",
    type: "multi",
    selection: purchaseOrderStatus,
  },
  {
    label: "Select File Status",
    value: "file_status",
    type: "multi",
    selection: purchaseOrdersFileStatus,
  },
  {
    label: "Select Provider",
    value: "provider",
    type: "multi",
    selection: providers,
  },
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: productionOrdersSort,
  },
  {
    type: "range",
    title: "Total Parts:",
    set: [
      {
        label: "Min #",
        value: "total_parts_gte",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_parts_lte",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Total QTY:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_gte",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_lte",
        type: "text",
      },
    ],
  },
];
