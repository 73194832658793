import React from "react";

import PurchaseOrderCreate from "../purchase_order_new/purchaseOrderNew";
import PartTotal from "./partTotal";

export default function PartTotalHome({
  data,
  setUrl,
  setPostData,
  postData,
  setOrderButton,
  loading,
  filters,
  setFilters,
  partFilterSetup,
  showFilter,
  setShowFilter,
  counter,
  setCounter,
  createPostandUpdate,
  orderButton,
  setLoadingOrder,
  loadingOrder,
  setData,
  freightType,
  setFreightType,
}) {
  return !loading && !orderButton ? (
    <PartTotal
      data={data}
      setData={setData}
      setUrl={setUrl}
      setPostData={setPostData}
      postData={postData}
      setOrderButton={setOrderButton}
      loading={loading}
      filter={true}
      filters={filters}
      setFilters={setFilters}
      partFilterSetup={partFilterSetup}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      counter={counter}
      setCounter={setCounter}
    />
  ) : (
    <PurchaseOrderCreate
      data={data}
      setData={setData}
      setUrl={false}
      setPostData={setPostData}
      postData={postData}
      setOrderButton={setOrderButton}
      createPostandUpdate={createPostandUpdate}
      loading={loading}
      loadingOrder={loadingOrder}
      counter={counter}
      setCounter={setCounter}
      freightType={freightType}
      setFreightType={setFreightType}
    />
  );
}
