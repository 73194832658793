import React from "react";
import TableHeadItem from "./tablehead";
import Alerts from "./msgAlert";
import Filter from "../components/filters/filter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { Typography } from "@material-ui/core";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FooterNavMain from "../components/footer/footerMain";
import Tooltip from "@mui/material/Tooltip";

export default function TableRenderer({
  data,
  header,
  component,
  title,
  setUrl,
  pagination,
  filter,
  filters,
  setFilters,
  filterSetup,
  showFilter,
  setShowFilter,
  hover,
  months,
  productionorder_id,
  doneButton,
  submit,
  posting,
  commitAll,
  uncommitAll,
  maxHeight = 560,
  ...props
}) {
  // const [showFilter, setShowFilter] = useState(false);
  const ListItem = component;

  //! check
  const parser = (url) => {
    const next_url = url.split("/").slice(3).join("/");
    setUrl(next_url);
  };
  let tbodyData;
  if (Array.isArray(data)) {
    tbodyData = data;
  } else if (data) {
    tbodyData = data.results;
  }
  if (hover) {
    hover = "hover";
  }
  // !check if i still neeed filter prop
  return tbodyData && tbodyData.length > 0 ? (
    <>
      <Typography
        className="d-flex justify-content-center"
        style={{ text: "center" }}
        sx={{ flex: "1 1 100%" }}
        variant="h4"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {!showFilter && filter && tbodyData.length > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
          className="d-flex justify-content-end"
        >
          <Tooltip title="Filter">
            <IconButton
              className="mr-3"
              onClick={() => setShowFilter(true)}
              variant="outlined"
              type="submit"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
      {filter && showFilter && (
        <Toolbar
          className="d-flex justify-content-end"
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Filter
            filters={filters}
            setFilters={setFilters}
            filterSetup={filterSetup}
            setShowFilter={setShowFilter}
          />
        </Toolbar>
      )}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: maxHeight }}>
          <Table
            className="table table-hover"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {header.map((h) => {
                  return (
                    <TableHeadItem
                      key={h.id}
                      item={h}
                      commitAll={commitAll}
                      uncommitAll={uncommitAll}
                      data2={data}
                    />
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tbodyData.map((item, idx) => {
                return (
                  <ListItem
                    key={idx}
                    idx={idx}
                    data={item}
                    hover={hover}
                    months={months}
                    {...props}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          <FooterNavMain
            posting={posting}
            pagination={pagination}
            data={data.next}
            urlprevius={data.previous}
            urlnext={data.next}
            setData={parser}
            redirect={props.redirect}
            productionorder_id={productionorder_id}
            doneButton={doneButton}
            submit={submit}
          />
        }
      </Paper>
    </>
  ) : (
    <>
      <Typography
        className="d-flex justify-content-center"
        style={{ text: "center" }}
        sx={{ flex: "1 1 100%" }}
        variant="h4"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {!showFilter && filter && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
          className="d-flex justify-content-end"
        >
          <Tooltip title="Filter">
            <IconButton
              className="mr-3"
              onClick={() => setShowFilter(true)}
              variant="outlined"
              type="submit"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
      {filter && showFilter && (
        <Toolbar
          className="d-flex justify-content-end"
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Filter
            filters={filters}
            setFilters={setFilters}
            filterSetup={filterSetup}
            setShowFilter={setShowFilter}
          />
        </Toolbar>
      )}
      <Alerts msg="No data found." />
    </>
  );
}
