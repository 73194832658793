import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "./components/navbar_main/navbarMain";
import Login from "./components/login/login";
import Logout from "./components/login/logout";
import "./css/hover.css";

import {
  AuthRoute,
  ProtectedRoute,
  ProtectedVendorRoute,
} from "./util/route_util";

import AddPart from "./components/purchase_forecast/new_part";
import ContactUs from "./components/support/supportContactUs";
import AllParts from "./components/all_parts/allParts";
import NewPart from "./components/new_part/newPart";
import ProductionOrderNew from "./components/production_orders/productionOrderNew";
import ProductionOrders from "./components/production_orders/productionOrders";
//test
import ProductionOrderItems from "./components/production_order_items/productionOrderItems";
import Inventory from "./components/inventory/inventory";
// import SalesReportsDetail from "./components/sales_reports_detail/salesReportsHome";
import SalesReports from "./components/sales_reports/salesreports";
import SalesReportsItem from "./components/sales_reports_items/salesReportItems";
import PartTotalProvider from "./components/part_order_total_provider/partTotalProvider";
import PurchaseOrders from "./components/purchase_orders/purchaseOrders";
import PurchaseOrderItems from "./components/purchase_order_items/purchaseOrderItems";
import ProductionOrderItemsAdd from "./components/production_order_items/productionOrderItemsAdd";
import PurchaseForecast from "./components/forecast_report/forecast";
import PartTotalMain from "./components/part_order_total_admin/partMain";
import PurchaseOrdersProvider from "./components/purchase_orders_provider/purchaseOrders";
import Home from "./components/home/home";
// rmeove comment
function App() {
  return (
    <>
      <Router>
        <Switch>
          <AuthRoute exact path="/login" component={Login} />
          <>
            <Navbar />

            <Route exact path="/logout" component={Logout} />
            <ProtectedRoute exact path="/part/new" component={NewPart} />
            <ProtectedRoute exact path="/parts" component={AllParts} />
            <ProtectedRoute
              exact
              path="/productionorder/new"
              component={ProductionOrderNew}
            />
            <ProtectedRoute
              exact
              path="/productionorder/:productionorder_id/add"
              component={ProductionOrderItemsAdd}
            />
            <ProtectedRoute exact path="/test" component={AddPart} />
            <ProtectedRoute
              exact
              path="/productionorders/:status?"
              component={ProductionOrders}
            />
            <ProtectedRoute
              exact
              path="/purchaseorders"
              component={PurchaseOrders}
            />
            <ProtectedRoute
              exact
              path="/reports/forecast"
              component={PurchaseForecast}
            />
            <ProtectedRoute
              exact
              path="/purchaseorders/new/:user_id"
              component={PartTotalMain}
            />
            <ProtectedRoute
              exact
              path="/productionorder/:productionorder_id/items"
              component={ProductionOrderItems}
            />
            <ProtectedRoute
              exact
              path="/reports/inventory"
              component={Inventory}
            />
            <ProtectedRoute
              exact
              path="/reports/sales"
              component={SalesReports}
            />
            <ProtectedRoute
              exact
              path="/reports/sales/:salesreport_id/items"
              component={SalesReportsItem}
            />

            <ProtectedRoute
              exact
              path="/purchaseorders/:purchaseorder_id/items"
              component={PurchaseOrderItems}
            />
            <ProtectedVendorRoute exact path="/support" component={ContactUs} />
            <ProtectedVendorRoute
              exact
              path="/provider/part/totals"
              component={PartTotalProvider}
            />
            <ProtectedVendorRoute
              exact
              path="/provider/orders"
              component={ProductionOrders}
            />
            <ProtectedVendorRoute
              exact
              path="/provider/orders/:productionorder_id/items"
              component={ProductionOrderItems}
            />
            {/* <ProtectedVendorRoute
              exact
              path="/provider/orders/:productionorder_id/items"
              component={ProductionOrderItems}
            /> */}
            <ProtectedVendorRoute
              exact
              path="/provider/purchaseorders"
              component={PurchaseOrdersProvider}
            />
            <ProtectedVendorRoute
              exact
              path="/provider/purchaseorders/:purchaseorder_id/items"
              component={PurchaseOrderItems}
            />
            <ProtectedRoute exact path="/" component={Home} />
          </>
        </Switch>
      </Router>
    </>
  );
}
export default App;
