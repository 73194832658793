import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton, TextField } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";

export default function PartTotalItem({ data, ...props }) {
  const {
    production_quantity_total,
    requested_quantity_total,
    name,
    prodorderitem_part,
  } = data;
  const { updateAPI } = props;
  const [producedQty, setProducedQty] = useState(production_quantity_total);
  const [producedQtyCopy, setProducedQtyCopy] = useState(
    production_quantity_total
  );
  //! check this
  const [submitStatus, setSubmitStatus] = useState(false);
  let color;
  let balance = requested_quantity_total - producedQty;
  if (balance === 0) {
    color = "bg-success";
  } else if (balance === requested_quantity_total) {
    color = "bg-danger";
  } else {
    color = "bg-warning";
  }

  const onSubmitChange = () => {
    setProducedQty(producedQtyCopy);
    updateAPI(prodorderitem_part, producedQtyCopy);
    setSubmitStatus(true);
  };

  const doEnter = (e) => {
    if (e.key === "Enter") {
      onSubmitChange();
      setSubmitStatus(true);
    }
  };

  const copy = () => {
    setProducedQtyCopy(requested_quantity_total);
    setProducedQty(requested_quantity_total);
    updateAPI(prodorderitem_part, requested_quantity_total);
    setSubmitStatus(true);
  };
  const renderEdit = () => {
    if (submitStatus === false) {
      return (
        <Tooltip title="Submit">
          <IconButton onClick={() => onSubmitChange()}>
            <CheckIcon
              value="Submit"
              disabled={producedQty > requested_quantity_total}
            >
              Submit
            </CheckIcon>
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Edit">
          <IconButton onClick={() => setSubmitStatus(false)}>
            <EditIcon value="Edit">Edit</EditIcon>
          </IconButton>
        </Tooltip>
      );
    }
  };

  const onChangeStatus = (e) => {
    // setSubmitStatus(true);
    e.target.value < 0
      ? setProducedQtyCopy(0)
      : setProducedQtyCopy(e.target.value);
  };

  return (
    prodorderitem_part.length > 0 && (
      <TableRow className={`Cell ${color}`}>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="right">{requested_quantity_total}</TableCell>
        <TableCell align="center">
          <Tooltip title="Add All">
            <IconButton onClick={() => copy()}>
              <CopyAllIcon
                value="Submit"
                disabled={producedQty > requested_quantity_total}
              >
                Submit
              </CopyAllIcon>
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          <TextField
            disabled={submitStatus}
            name={"produced_quantity"}
            label={"Produced Quantity"}
            variant="standard"
            type="number"
            max={parseInt(requested_quantity_total)}
            onChange={(e) => onChangeStatus(e)}
            onKeyPress={(e) => doEnter(e)}
            value={producedQtyCopy}
          />
        </TableCell>
        <TableCell align="center">{balance}</TableCell>
        <TableCell align="center">{renderEdit()}</TableCell>
      </TableRow>
    )
  );
}
