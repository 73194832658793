import React, { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import Tooltip from "@mui/material/Tooltip";

import "./partOrder.css";
export default function PartTotaldata({
  data,
  setPostData,
  postData,
  idx,
  setData,
  ...props
}) {
  const { setCounter, counter, limit, allData } = props;
  const [errors, setErrors] = useState({ text: "", status: false });
  const [quantity, setQuantity] = useState(data.production_quantity_total);
  const [showOrders, setShowOrders] = useState(false);
  const handleCommit = (e) => {
    e.preventDefault();
    if (counter + parseInt(quantity) > limit) {
      setErrors({ text: "Quantity exceeds limit", status: true });
    } else if (parseInt(quantity) > data.production_quantity_total) {
      setErrors({ text: "Quantity exceeds produced", status: true });
    } else {
      // const new_obj = [...postData];
      // new_obj.push({
      //   id: data.part_id,
      //   part: data.part__name,
      //   quantity: quantity,
      //   requested_quantity: data.requested_quantity - quantity,
      //   production_quantity: data.production_quantity - quantity,
      //   production_orders: data.production_orders,
      // });
      const new_data = { ...allData };
      new_data["results"][idx]["add"] = false;
      new_data["results"][idx]["quantity"] = quantity;
      setData(new_data);
      // setPostData(new_obj);
      // setShowCommit(false);
      let newCount = counter + parseInt(quantity);
      setErrors({ text: "", status: false });
      setCounter(newCount);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const new_data = { ...allData };
    new_data["results"][idx]["add"] = true;
    new_data["results"][idx]["quantity"] = 0;
    setData(new_data);
    let newCount = counter;
    setCounter((newCount -= quantity));
  };
  return (
    data.prodorderitem_part.length > 0 && (
      <>
        <TableRow className="Cell">
          <TableCell align="center">{data.name}</TableCell>
          <TableCell align="center">{data.production_quantity_total}</TableCell>
          <TableCell align="center">{data.requested_quantity_total}</TableCell>
          <TableCell align="center">{data.balance}</TableCell>
          <TableCell align="center">
            {showOrders ? (
              <Tooltip title="collapse">
                <IconButton onClick={() => setShowOrders(false)}>
                  <ExpandLessIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="expand">
                <IconButton onClick={() => setShowOrders(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
          <TableCell align="center">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "20ch", height: "7.5ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="quantity"
                label="Quantity"
                value={quantity}
                variant="filled"
                type="number"
                // InputProps={{
                //   inputProps: { min: 0, max: data.production_quantity_total },
                // }}
                error={errors["status"]}
                // max={parseInt(data.production_quantity_total)}
                onChange={(e) =>
                  e.target.value < 0
                    ? setQuantity(0)
                    : setQuantity(e.target.value)
                }
                disabled={!data.add}
                helperText={errors["text"]}
              />
            </Box>
          </TableCell>
          <TableCell align="center">
            {data.add ? (
              <Tooltip title="Add To Cart">
                <IconButton onClick={(e) => handleCommit(e)}>
                  <AddShoppingCartIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Remove From Cart">
                <IconButton onClick={(e) => handleDelete(e)}>
                  <RemoveShoppingCartIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
        {showOrders && (
          <tbody className="table table-hover table-responsive">
            <thead>
              <TableRow>
                <TableCell align="center">Production Order</TableCell>
                <TableCell align="center">Requested Quantity</TableCell>
              </TableRow>
            </thead>
            {data.prodorderitem_part.map((item) => {
              return (
                <TableRow className="Cell">
                  <TableCell align="center">{item.prod_order_name}</TableCell>
                  <TableCell align="center">
                    {item.requested_quantity}
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        )}
      </>
    )
  );
}
