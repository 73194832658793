import React from "react";
import { Toolbar } from "@material-ui/core";
import ProdFooterNav from "./prodOrdersFooter";
import PaginationFooter from "./pagination";
export default function FooterNavMain({
  posting,
  submit,
  doneButton,
  urlprevius,
  urlnext,
  setData,
}) {
  return (
    <Toolbar
      className={
        (urlprevius || urlnext) && urlprevius
          ? "d-flex justify-content-between"
          : "d-flex justify-content-end"
      }
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {(urlprevius || urlnext) && (
        <PaginationFooter
          urlprevius={urlprevius}
          urlnext={urlnext}
          setData={setData}
        />
      )}
      {doneButton && (
        <div>
          <ProdFooterNav posting={posting} submit={submit} />
        </div>
      )}
    </Toolbar>
  );
}
