import { purchaseOrderItemsSort } from "./purchaseOrderItemConfig";

export const purchaseOrderItemFilterSetup = [
  {
    label: "Search Part",
    value: "part",
    type: "text",
  },

  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: purchaseOrderItemsSort,
  },
  {
    type: "range",
    title: "Original QTY:",
    set: [
      {
        label: "Min #",
        value: "quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "quantity_lt",
        type: "text",
      },
    ],
  },
];
