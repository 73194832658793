import React, { useState } from "react";
import { IconButton } from "@mui/material";
import FilterItem from "./filterItem";
import Tooltip from "@mui/material/Tooltip";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export default function Filter({
  filters,
  setFilters,
  filterSetup,
  setShowFilter,
}) {
  const [filterClone, setFilterClone] = useState(filters);
  const onReset = () => {
    let copy = { ...filters };
    for (const property in copy) {
      copy[property] = "";
    }
    setFilters(copy);
  };
  const submitForm = (e) => {
    e.preventDefault();
    setFilters(filterClone);
  };
  return (
    <form
      className="d-flex align-items-center pr-3"
      onSubmit={(e) => {
        submitForm(e);
      }}
    >
      {filterSetup.map((item, idx) => (
        <FilterItem
          item={item}
          key={idx}
          filters={filters}
          setFilters={setFilters}
          setFilterClone={setFilterClone}
          filterClone={filterClone}
        />
      ))}
      <Tooltip title="Clear Filters">
        <IconButton aria-label="delete" onClick={() => onReset()}>
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Submit">
        <IconButton
          aria-label="delete"
          type="submit"
          onClick={() => setFilters(filterClone)}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Collapse">
        <IconButton aria-label="delete" onClick={() => setShowFilter(false)}>
          <CloseFullscreenIcon />
        </IconButton>
      </Tooltip>
    </form>
  );
}
