import React from "react";
import { useHistory } from "react-router";
import "../../css/basictable.css";
import { formatDate } from "../../util/formatDate";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function SalesReportsItem({ data, hover }) {
  const { id, date, total_quantity, updated_at } = data;
  const { push } = useHistory();
  return (
    <TableRow
      className={`Cell ${hover}`}
      onClick={() => push(`/reports/sales/${id}/items`)}
      align="center"
    >
      <TableCell align="center">{formatDate(date)}</TableCell>
      <TableCell align="center">{total_quantity}</TableCell>
      <TableCell align="center">{formatDate(updated_at)}</TableCell>
    </TableRow>
  );
}
