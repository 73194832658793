import { sort } from "./partTotalsConfig";

export const partFilterSetup = [
  { label: "Search Part", value: "search", type: "text" },

  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  {
    type: "range",
    title: "Balance:",
    set: [
      {
        label: "Min #",
        value: "balance_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "balance_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Production QTY:",
    set: [
      {
        label: "Min #",
        value: "production_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "production_quantity_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Requested QTY:",
    set: [
      {
        label: "Min #",
        value: "requested_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "requested_quantity_lt",
        type: "text",
      },
    ],
  },
];
