import { sort } from "./salesReportConfig";

export const salesReportFilterSetup = [
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  { label: "Start Date", value: "start_date", type: "text" },
  { label: "End Date", value: "end_date", type: "text" },
];
