import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import SalesReportsItemList from "./salesReportItemList";
import TableRenderer from "../../util/tableRenderer";
import { salesReportItemFilterSetup } from "../filters/config/sales_report_items/salesReportItemSelection";

export default function SalesReportsItem() {
  let params = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    sort: "",
    search: "",
    total_quantity_gt: "",
    total_quantity_lt: "",
  });

  const salesreport_id = params.salesreport_id;
  const load_url = `esd/salesreports/${salesreport_id}/items/?total_quantity_gt=${filters.total_quantity_gt}&total_quantity_lt=${filters.total_quantity_lt}&ordering=${filters.sort}&ordering=${filters.sort}&search=${filters.search}`;
  const load_url2 = `esd/salesreports/${salesreport_id}`;
  const salesreportData = useFetchAxios(load_url2);
  const { loading, data, setUrl } = useFetchAxios(load_url);
  const theadData = [
    { id: "part", label: "Part", minWidth: 100, align: "center" },
    {
      id: "total_quantity",
      label: "Total Quantity",
      minWidth: 100,
      align: "center",
    },
    {
      id: "main",
      label: "Main",
      minWidth: 200,
      align: "center",
    },
    { id: "vendor", label: "Vendor", minWidth: 200, align: "center" },
    { id: "fba", label: "FBA", minWidth: 200, align: "center" },
    { id: "rc", label: "Rough Country", minWidth: 200, align: "center" },
  ];

  useEffect(() => {
    const setData = () => {
      salesreportData.setUrl(load_url2);
      setUrl(load_url);
    };
    setData();
  }, [load_url, load_url2, salesreportData, setUrl]);

  return (
    <>
      {loading || salesreportData.loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={SalesReportsItemList}
          title={`Sales Report: ${salesreportData.data.date}`}
          setUrl={setUrl}
          pagination={true}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={salesReportItemFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      )}
    </>
  );
}
