import { sort } from "./salesReportItemConfig";

export const salesReportItemFilterSetup = [
  { label: "Search Part", value: "search", type: "text" },
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: sort,
  },
  {
    type: "range",
    title: "Total QTY:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_lt",
        type: "text",
      },
    ],
  },
];
