import React, { useEffect } from "react";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import Chart from "react-google-charts";

export default function BarChart() {
  const load_url = `esd/salesreports/?ordering=-date`;
  const { loading, data, setUrl } = useFetchAxios(load_url);
  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);
  let showData = [["Month", "Sales"]];
  if (!loading) {
    let tempData = data.results.slice(0, 12).reverse();
    tempData.forEach((ele) =>
      showData.push([ele.date.slice(0, 7), ele.total_quantity])
    );
  }

  return (
    showData.length > 1 && (
      <Chart
        width={"1000px"}
        height={"600px"}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={showData}
        options={{
          chart: {
            title: "Total Sales",
            subtitle: "Sales chart for the last 12 months.",
          },
        }}
      />
    )
  );
}
