import React, { useEffect, useState } from "react";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import PartsItem from "./allPartsList";
import { partsFilterSetup } from "../filters/config/all_parts/parts";

export default function AllParts() {
  const { loading, data, setUrl } = useFetchAxios("/esd/parts/");
  const theadData = [
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    { id: "id", label: "ID", minWidth: 200, align: "center" },
  ];
  const [showFilter, setShowFilter] = useState(false);

  const [filters, setFilters] = useState({
    search: "",
  });
  let load_url = `esd/parts/?search=${filters.search}`;

  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={PartsItem}
          title={"Parts"}
          setUrl={setUrl}
          pagination={true}
          filter={true}
          filters={filters}
          setFilters={setFilters}
          filterSetup={partsFilterSetup}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      )}
    </>
  );
}
