import React, { useState } from "react";
import axiosInstance from "../../hooks/axios";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const history = useHistory();
  const initialFormData = Object.freeze({
    user_name: "",
    password: "",
  });
  const [error, setError] = useState();

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`jwt/create`, {
        username: formData.user_name,
        password: formData.password,
      })
      .then((res) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        localStorage.setItem("isAuthenticated", true);
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + localStorage.getItem("access_token");
        axiosInstance.get(`/users/me`).then((res) => {
          localStorage.setItem("user_id", res.data.id);
          localStorage.setItem("role", res.data.is_staff);
          localStorage.setItem("user_name", res.data.username);
          if (localStorage.getItem("role") === "false") {
            history.push("/provider/part/totals");
          } else {
            history.push("/");
          }
        });
      })
      .catch((err) => setError(err));
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="user_name"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <Button
            type="submit"
            className={classes.submit + "btn btn-dark"}
            style={{
              width: "395px",
              textAlign: "center",
              marginTop: "11px",
              backgroundColor: "black",
              color: "white",
              padding: "12px 28px",
            }}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
          {error && (
            <Typography variant="caption" className="text-danger">
              Password or username is incorrect, Please try again
            </Typography>
          )}
        </form>
      </div>
    </Container>
  );
}
