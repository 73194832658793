import { productionOrderItemsSort, status } from "./productionOrderItemsConfig";

export const productionOrderItemFilterSetup = [
  { label: "Search Name", value: "search", type: "text" },
  {
    label: "Select Status",
    value: "status",
    type: "multi",
    selection: status,
  },
  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: productionOrderItemsSort,
  },
  {
    type: "range",
    title: "Original QTY:",
    set: [
      {
        label: "Min #",
        value: "original_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "original_quantity_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Requested QTY:",
    set: [
      {
        label: "Min #",
        value: "requested_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "requested_quantity_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Production QTY:",
    set: [
      {
        label: "Min #",
        value: "production_quantity_lt",
        type: "text",
      },
      {
        label: "Max #",
        value: "production_quantity_gt",
        type: "text",
      },
    ],
  },
  // {
  //   label: "Original QTY GT",
  //   value: "original_quantity_gt",
  //   type: "text",
  // },
  // {
  //   label: "Original QTY LT",
  //   value: "original_quantity_lt",
  //   type: "text",
  // },
];
