import React from "react";
import { buttonStyleContinue } from "../styles/styleConfig";
import {
  Button,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function PartReportForm({
  months,
  setMonths,
  newPart,
  submitForm,
  ...props
}) {
  const { detail, customers, vendorId, setVendorId } = props;
  let monthList = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const renderHeader = () => {
    let renderText;
    if (detail) {
      renderText = "Sales Report Forecast";
    } else if (newPart) {
      renderText = "Create New Production Order:";
    } else {
      renderText = "Add Items To Production Order:";
    }

    return (
      <Typography className="mb-3" variant="h5">
        {renderText}
      </Typography>
    );
  };
  return (
    <Container
      maxWidth="xs"
      component="form"
      className=" d-flex align-items-center flex-column border p-5 w-30"
      onSubmit={(e) => submitForm(e)}
    >
      {renderHeader()}
      {newPart && (
        <FormControl style={{ minWidth: 180, paddingBottom: 20 }}>
          <InputLabel id="demo-simple-select-label">Provider</InputLabel>

          <Select
            labelId="provider"
            id="provider"
            value={vendorId}
            label="Provider"
            defaultValue=""
            onChange={(e) => setVendorId(e.target.value)}
          >
            {customers.map((customer) => {
              return (
                <MenuItem key={customer.id} value={customer}>
                  {customer.company_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <FormControl style={{ minWidth: 180, paddingBottom: 20 }}>
        <InputLabel id="demo-simple-select-label">Months</InputLabel>
        <Select
          labelId="months"
          id="months"
          value={months}
          defaultValue=""
          label="Months"
          onChange={(e) => setMonths(e.target.value)}
        >
          {monthList.map((month) => {
            return (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        style={buttonStyleContinue}
        id="submit"
        type="submit"
        variant="outlined"
        endIcon={<NavigateNextIcon />}
      >
        Continue
      </Button>
    </Container>
  );
}
