export const purchaseOrderItemsSort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "quantity",
    label: "QTY - Ascending",
  },
  {
    value: "-quantity",
    label: "QTY - Descending",
  },
];
