import React from "react";
import "../../css/basictable.css";
import TableCell from "@mui/material/TableCell";

export default function InventoryLine({ data }) {
  return (
    <TableCell align="center">
      On Hand: {data.qty_on_hand} | On Order: {data.qty_order}
    </TableCell>
  );
}
