export const productionOrdersStatus = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "O",
    label: "Open",
  },
  {
    value: "F",
    label: "Fulfilled",
  },

  {
    value: "C",
    label: "Closed",
  },
];

export const providers = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "14",
    label: "FDW",
  },
  {
    value: "13",
    label: "GTV",
  },
  {
    value: "12",
    label: "JSG",
  },
  {
    value: "11",
    label: "SDI",
  },
  {
    value: "2",
    label: "SPL",
  },
  {
    value: "10",
    label: "VDP",
  },
];

export const productionOrdersSort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "created_at",
    label: "Created at - Ascending",
  },
  {
    value: "-created_at",
    label: "Created at - Descending",
  },
  {
    value: "closed_at",
    label: "Closed At - Ascending",
  },
  {
    value: "-closed_at",
    label: "Closed At - Descending",
  },
  {
    value: "total_quantity",
    label: "Total QTY - Ascending",
  },
  {
    value: "-total_quantity",
    label: "Total QTY - Descending",
  },
  {
    value: "total_parts",
    label: "Total Parts - Ascending",
  },
  {
    value: "-total_parts",
    label: "Total Parts - Descending",
  },
];
