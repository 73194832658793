import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
export default function SalesReportsItemList({ data }) {
  const { part, line_items, total_quantity } = data;
  let newObj = {};
  for (const key in line_items) {
    newObj[line_items[key].site] = line_items[key].quantity;
  }
  return (
    <TableRow className="Cell">
      <TableCell align="center">{part}</TableCell>
      <TableCell align="center">{total_quantity}</TableCell>
      <TableCell align="center">
        {newObj["Main"] ? newObj["Main"] : 0}
      </TableCell>
      <TableCell align="center">
        {newObj["Vendor"] ? newObj["Vendor"] : 0}
      </TableCell>
      <TableCell align="center">{newObj["FBA"] ? newObj["FBA"] : 0}</TableCell>
      <TableCell align="center">{newObj["RC"] ? newObj["RC"] : 0}</TableCell>
    </TableRow>
  );
}
