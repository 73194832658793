import React from "react";
import { useHistory } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { formatDate } from "../../util/formatDate";

export default function ProductionOrdersList({ data, ...props }) {
  let { admin, hover } = props;
  const {
    name,
    total_parts,
    total_quantity,
    created_at,
    closed_at,
    status,
    customer_company,
  } = data;
  const { push } = useHistory();

  let link = admin === "false" ? "/provider/orders/" : "/productionorder/";
  let bg;
  if (status === "Closed") {
    bg = "bg-danger";
  } else if (status === "Fulfilled") {
    bg = "bg-warning";
  } else if (status === "Open") {
    bg = "bg-success";
  }

  return (
    <TableRow
      align="center"
      className={hover}
      onClick={() => push(`${link + data.id}/items`)}
    >
      <TableCell align="center">{name}</TableCell>
      <TableCell align="center">{customer_company}</TableCell>
      <TableCell align="center">{total_parts}</TableCell>
      <TableCell align="center">{total_quantity}</TableCell>
      <TableCell align="center">{formatDate(created_at)}</TableCell>
      <TableCell align="center">{closed_at && formatDate(closed_at)}</TableCell>
      <TableCell align="center" className={bg}>
        {status}
      </TableCell>
    </TableRow>
  );
}
