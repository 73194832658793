import emailjs from "emailjs-com";
import React, { useState } from "react";

export const ContactUs = () => {
  const vendor = localStorage.getItem("user_name");
  const [error, setError] = useState("");
  const {
    REACT_APP_SERVICE_ID,
    REACT_APP_TEMPLATE_SUPPORT_ID,
    REACT_APP_USER_ID,
  } = process.env;
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        REACT_APP_SERVICE_ID,
        REACT_APP_TEMPLATE_SUPPORT_ID,
        e.target,
        REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          setError(error.text);
        }
      );
    e.target.reset();
  }

  return error ? (
    <div>{error}</div>
  ) : (
    <div>
      <div className="container">
        <form onSubmit={sendEmail}>
          <div className="row pt-5 mx-auto">
            <div className="col-8 form-group mx-auto">
              <input
                readOnly
                type="text"
                className="form-control"
                value={vendor}
                name="vendor"
              />
            </div>
            <div className="col-8 form-group pt-2 mx-auto">
              <input
                required
                type="email"
                className="form-control"
                placeholder="Email Address"
                name="from"
              />
            </div>
            <div className="col-8 form-group pt-2 mx-auto">
              <input
                required
                type="text"
                className="form-control"
                placeholder="Subject"
                name="from_name"
              />
            </div>
            <div className="col-8 form-group pt-2 mx-auto">
              <textarea
                className="form-control"
                id=""
                cols="30"
                rows="8"
                placeholder="Your message"
                name="message"
              ></textarea>
            </div>
            <div className="col-8 pt-3 mx-auto d-flex justify-content-center">
              <input
                type="submit"
                className="btn btn-info btn-dark"
                style={{
                  width: "250px",
                  textAlign: "center",
                }}
                value="Send Message"
              ></input>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
