export const formatDate = (date) => {
  let new_date = new Date(date);
  new_date = new_date.setDate(new_date.getDate() + 1);
  let formatString = new Date(new_date).toLocaleString().slice(0, 10);
  if (formatString.slice(-1) === ",") {
    return formatString.slice(0, 9);
  } else {
    return formatString.slice(0, 8);
  }
};
