import React from "react";
import "../../css/basictable.css";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PurchaseForecastMonths from "./purchaseForecastMonths";

export default function ForecastItem({ data, idx, ...props }) {
  const {
    name,
    total_sales,
    sales_average_6_months,
    inventory,
    in_transit,
    in_factory,
    total_inventory,
    sri_part,
  } = data;

  return (
    <TableRow className="Cell">
      <TableCell align="center">{name}</TableCell>
      <PurchaseForecastMonths sri_part={sri_part} months={props.months} />
      <TableCell align="center" className="border-left">
        {total_sales}
      </TableCell>
      <TableCell align="center">{sales_average_6_months}</TableCell>
      <TableCell align="center" className="border-left">
        {inventory}
      </TableCell>
      <TableCell align="center">{in_transit}</TableCell>
      <TableCell align="center">{in_factory}</TableCell>
      <TableCell align="center" className="border-right">
        {total_inventory}
      </TableCell>
    </TableRow>
  );
}
