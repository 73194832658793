import React, { useState } from "react";
import PartTotalItem from "./partTotalItemAdmin";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRenderer from "../../util/tableRenderer";
import { Button } from "@mui/material";
import { buttonStyleOrder } from "../../styles/styleConfig";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export default function PartTotal({
  data,
  setUrl,
  setPostData,
  postData,
  setOrderButton,
  loading,
  setData,
  ...props
}) {
  const theadData = [
    { id: "name", label: "Part", minWidth: 100, align: "center" },
    {
      id: "total_produced",
      label: "Total Produced",
      minWidth: 200,
      align: "center",
    },
    {
      id: "total_requested",
      label: "Total Requested",
      minWidth: 200,
      align: "center",
    },
    {
      id: "production_balance",
      label: "Production Balance",
      minWidth: 200,
      align: "center",
    },
    { id: "open_orders", label: "Open Orders", minWidth: 200, align: "center" },
    { id: "quantity", label: "Quantity", minWidth: 200, align: "center" },
    {
      id: "add_or_remove",
      label: "Add/Remove",
      minWidth: 200,
      align: "center",
    },
  ];
  const {
    filters,
    setFilters,
    showFilter,
    setShowFilter,
    partFilterSetup,
    counter,
    setCounter,
  } = props;

  const [addAllStatus, setAddAllStatus] = useState(true);

  const addAll = () => {
    let nextCounter = counter;
    let copy = { ...data };
    copy.results.forEach((element) => {
      if (element.production_quantity_total > 0) {
        element["add"] = false;
        element["quantity"] = element.production_quantity_total;
        nextCounter += element.production_quantity_total;
      }
    });
    setData(copy);
    setCounter(nextCounter);
    setAddAllStatus(!addAllStatus);
  };

  const removeAll = () => {
    let copy = { ...data };
    copy.results.forEach((element) => {
      if (element.add === false) {
        element["add"] = true;
      }
    });
    setData(copy);
    setCounter(0);
    setAddAllStatus(!addAllStatus);
  };

  let limit = 7500;
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <TableRenderer
            data={data}
            allData={data}
            header={theadData}
            component={PartTotalItem}
            setData={setData}
            title={`New Purchase Order | Purchase Order Total Quantity: ${counter}/${limit}`}
            setUrl={setUrl}
            setPostData={setPostData}
            postData={postData}
            filter={true}
            filters={filters}
            setFilters={setFilters}
            filterSetup={partFilterSetup}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setCounter={setCounter}
            counter={counter}
            limit={limit}
          />
          {data.results.length > 0 && (
            <div className="d-flex justify-content-between align-items-center me-3 mt-3 ">
              <Button
                onClick={() => (addAllStatus ? addAll() : removeAll())}
                className="ms-3"
                style={buttonStyleOrder}
                id="submit"
                type="submit"
                variant="outlined"
                // endIcon={<ShoppingCartIcon />}
              >
                {addAllStatus ? "Add All Produced" : "Remove All Items"}
              </Button>
              <Button
                onClick={() => setOrderButton(true)}
                className="ms-3"
                style={buttonStyleOrder}
                id="submit"
                type="submit"
                variant="outlined"
                endIcon={<ShoppingCartIcon />}
              >
                View Purchase Order
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
