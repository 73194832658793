export const theadDataPush = (admin) => {
  let theadData = [
    { id: "part", label: "Part", minWidth: 100, align: "center" },
    { id: "status", label: "Status", minWidth: 50, align: "center" },
    {
      id: "original_quantity",
      label: "Original Quantity",
      minWidth: 100,
      align: "center",
    },
    {
      id: "requested_quantity",
      label: "Requested Quantity",
      minWidth: 100,
      align: "center",
    },
    {
      id: "production_quantity",
      label: "Production Quantity",
      minWidth: 100,
      align: "center",
    },
    { id: "edit", label: "Edit", minWidth: 100, align: "center" },
  ];
  admin === "true" &&
    theadData.push({
      id: "delete",
      label: "Delete",
      minWidth: 100,
      align: "center",
    });
  return theadData;
};
