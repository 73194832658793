import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import Nav from "react-bootstrap/Nav";
import React, { useEffect } from "react";
export default function NavbarProvider() {
  const load_url = `esd/purchaseorders/?total_quantity_gte=&total_quantity_lte=&total_parts_gte=&total_parts_lte=&customer=12&status=&file_status=P`;
  const { loading, data, setUrl } = useFetchAxios(load_url);
  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);
  console.log(data);

  return (
    <>
      <Navbar className="h5" bg="light" expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "200px" }}
            navbarScroll
          >
            <NavDropdown title="Production Orders" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/provider/part/totals">
                Part Totals
              </NavDropdown.Item>
              <NavDropdown.Item href="/provider/orders">
                Open orders
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/provider/purchaseorders">
              <div class="text-warning">
                | Purchase Orders{data && ` | ${data.count} Open Orders |`}
              </div>
            </Nav.Link>
            <Nav.Link href="/support">Support</Nav.Link>
          </Nav>
          <Nav>
            {localStorage.getItem("access_token") ? (
              <Nav.Link href="/logout">Logout</Nav.Link>
            ) : (
              <Nav.Link href="/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
