import { LinearProgress } from "@material-ui/core";
import React, { useEffect } from "react";

import useFetchAxios from "../../hooks/useFetchAxiosBasic";
import TableRenderer from "../../util/tableRenderer";
import PurchaseOrderFilesItem from "./purchaseOrderFilesItem";

export default function PurchaseOrderFiles({ purchaseorder_id }) {
  const load_url = `esd/files/?name=&type=&purchaseorder=${purchaseorder_id}`;
  const { loading, data, setUrl } = useFetchAxios({ endpoint: load_url });

  const theadData = [
    { id: "name", label: "Name", minWidth: 100, align: "center" },
    { id: "status", label: "Status", minWidth: 200, align: "center" },
    { id: "type", label: "Type", minWidth: 200, align: "center" },
    { id: "created_at", label: "Created At", minWidth: 200, align: "center" },
    { id: "download", label: "Download", minWidth: 200, align: "center" },
  ];

  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <TableRenderer
          data={data}
          header={theadData}
          component={PurchaseOrderFilesItem}
          title={`Uploaded Files`}
          setUrl={setUrl}
          pagination={false}
          filter={false}
        />
      )}
    </>
  );
}
