import { useEffect, useState } from "react";
import axios from "axios";

// const baseURLAuth = "http://127.0.0.1:8000/api/";
const baseURL = "https://danielguardado.pythonanywhere.com/";

const useFetchAxios = () => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!url) return;
    const fetchData = async () => {
      const axiosInstance = axios.create({
        baseURL: baseURL + url,
        timeout: 15000,
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });
      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        async function (error) {
          const originalRequest = error.config;

          if (typeof error.response === "undefined") {
            // alert(
            //   "A server/network error occurred. " +
            //     "Looks like CORS might be the problem. " +
            //     "Sorry about this - we will get it fixed shortly."
            // );
            return Promise.reject(error);
          }

          if (
            error.response.status === 401 &&
            originalRequest.url ===
              "https://danielguardado.pythonanywhere.com/api/token/refresh/"
          ) {
            window.location.href = "/login/";
            return Promise.reject(error);
          }

          if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
          ) {
            const refreshToken = localStorage.getItem("refresh_token");
            if (refreshToken) {
              const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

              // exp date in token is expressed in seconds, while now() returns milliseconds:
              const now = Math.ceil(Date.now() / 1000);

              if (tokenParts.exp > now) {
                return axiosInstance
                  .post(
                    "httpsdanielguardado.pythonanywhere.com/api/token/refresh/",
                    {
                      refresh: refreshToken,
                    }
                  )
                  .then((response) => {
                    localStorage.setItem("access_token", response.data.access);
                    // localStorage.setItem("refresh_token", response.data.refresh);

                    axiosInstance.defaults.headers["Authorization"] =
                      "JWT " + response.data.access;
                    originalRequest.headers["Authorization"] =
                      "JWT " + response.data.access;

                    return axiosInstance(originalRequest);
                  })
                  .catch((err) => {
                    setError(err);
                  });
              } else {
                setError("Refresh token is expired", tokenParts.exp, now);
                window.location.href = "/login/";
              }
            } else {
              setError("Refresh token not available.");
              window.location.href = "/login/";
            }
          }

          // specific error handling done elsewhere
          return Promise.reject(error);
        }
      );
      setLoading(true);
      await axiosInstance
        .get(baseURL + url)
        // .get(url, { headers: { Authorization: "Bearer " + jwt } })
        .then((res) => {
          if (res.status === 200) {
            for (const property in res.data.results) {
              res.data.results[property]["add"] = true;
            }
            setData(res.data);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => setLoading(false));
    };
    fetchData();
  }, [url]);
  return { loading, data, error, setUrl, setData, url };
};

export default useFetchAxios;
