export const providers = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "14",
    label: "FDW",
  },
  {
    value: "13",
    label: "GTV",
  },
  {
    value: "12",
    label: "JSG",
  },
  {
    value: "11",
    label: "SDI",
  },
  {
    value: "2",
    label: "SPL",
  },
  {
    value: "10",
    label: "VDP",
  },
];

export const purchaseOrdersFileStatus = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "P",
    label: "Pending",
  },
  {
    value: "C",
    label: "Completed",
  },
];

export const productionOrdersSort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "created_at",
    label: "Created at - Ascending",
  },
  {
    value: "-created_at",
    label: "Created at - Descending",
  },
  {
    value: "total_quantity",
    label: "Total Quantity - Ascending",
  },
  {
    value: "-total_quantity",
    label: "Total Quantity - Descending",
  },
  {
    value: "total_parts",
    label: "Total Parts - Ascending",
  },
  {
    value: "-total_parts",
    label: "Total Parts - Descending",
  },
];

export const purchaseOrderStatus = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "A",
    label: "Accepted",
  },

  {
    value: "C",
    label: "Closed",
  },
  {
    value: "P",
    label: "Pending",
  },
];
