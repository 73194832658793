export const buttonStyle = {
  borderRadius: 35,
  borderColor: "#212529",
  color: "#212529",
};
export const buttonStyleDisabled = {
  borderRadius: 35,
  borderColor: "#212529",
  color: "#212529",
  backgroundColor: "#212529",
};
export const buttonStyleContinue = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 170,
};
export const buttonStyleDone = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 150,
  height: 30,
};
export const buttonStyleAddPart = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 200,
  height: 30,
};
export const buttonStyleAddPartSuccess = {
  borderRadius: 35,
  backgroundColor: "green",
  color: "white",
  width: 200,
  height: 30,
};

export const buttonStyleCommit = {
  borderRadius: 1,
  backgroundColor: "white",
  color: "#212529",
  width: 40,
  height: 25,
};

export const buttonStyleOrder = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 300,
};
export const buttonStyleCreate = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 230,
};
export const buttonStyleCreateLoading = {
  borderRadius: 35,
  backgroundColor: "#808080",
  color: "white",
  width: 230,
};
export const buttonStyleAddItems = {
  borderRadius: 35,
  backgroundColor: "#212529",
  color: "white",
  width: 230,
};
