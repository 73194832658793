import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import PartReportForm from "../../util/PartReportForm";
import axiosInstance from "../../hooks/usePostAxios";
import useFetchAxios from "../../hooks/useFetchAxiosBasic";

export default function ProductionOrderNew() {
  const [months, setMonths] = useState("");
  const [error, setError] = useState("");
  const [vendorId, setVendorId] = useState("");

  const { push } = useHistory();
  const load_url = `esd/customers/all`;
  const { loading, data: customers } = useFetchAxios({ endpoint: load_url });

  const createProductionOrder = (e) => {
    e.preventDefault();
    let date =
      vendorId["alias"] +
      "_" +
      new Date().toJSON().slice(0, 16).replace(/-/g, "_").replace("T", "_");

    let data = {
      name: date,
      customer: vendorId["id"],
    };

    axiosInstance
      .post("esd/productionorders/", data)
      .then((response) => {
        push({
          pathname: `/productionorder/${response.data.id}/add`,
          props: { months: months },
        });
      })
      .catch((error) => {
        setError(error);
      });
  };
  return error ? (
    <div>{error}</div>
  ) : loading ? (
    <LinearProgress />
  ) : (
    <PartReportForm
      months={months}
      setMonths={setMonths}
      newPart={true}
      submitForm={createProductionOrder}
      loading={loading}
      customers={customers}
      vendorId={vendorId}
      setVendorId={setVendorId}
    />
  );
}
