export const sort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "requested_quantity_total",
    label: "Requested Quantity - Ascending",
  },
  {
    value: "-requested_quantity_total",
    label: "Requested Quantity - Descending",
  },
  {
    value: "production_quantity_total",
    label: "Production Quantity - Ascending",
  },
  {
    value: "-production_quantity_total",
    label: "Production Quantity - Descending",
  },
  {
    value: "balance",
    label: "Balance - Ascending",
  },
  {
    value: "-balance",
    label: "Balance - Descending",
  },
];
