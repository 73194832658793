import {
  productionOrdersSort,
  productionOrdersStatus,
  providers,
} from "./productionOrdersConfig";

export const productionOrderFilterSetup = [
  { label: "Search Name", value: "search", type: "text" },
  {
    label: "Select Vendor",
    value: "customer",
    type: "multi",
    selection: providers,
  },
  {
    label: "Select Status",
    value: "status",
    type: "multi",
    selection: productionOrdersStatus,
  },

  {
    label: "Select Sort",
    value: "sort",
    type: "multi",
    selection: productionOrdersSort,
  },
  {
    type: "range",
    title: "Parts QTY:",
    set: [
      {
        label: "Min #",
        value: "total_parts_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_parts_lt",
        type: "text",
      },
    ],
  },
  {
    type: "range",
    title: "Total QTY:",
    set: [
      {
        label: "Min #",
        value: "total_quantity_gt",
        type: "text",
      },
      {
        label: "Max #",
        value: "total_quantity_lt",
        type: "text",
      },
    ],
  },
  // { label: "Total QTY LT", value: "total_quantity_lt", type: "text" },
  // { label: "Total QTY GT", value: "total_quantity_gt", type: "text" },
  // { label: "Total Parts LT", value: "total_parts_lt", type: "text" },
  // { label: "Total QTY GT", value: "total_parts_gt", type: "text" },
];
