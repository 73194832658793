import React from "react";
import Grid from "@mui/material/Grid";
import ProductionOrders from "../production_orders/productionOrders";
import PurchaseOrders from "../purchase_orders/purchaseOrders";
import BarChart from "./barchart";
export default function Home() {
  return (
    <div className="d-flex justify-content-center">
      <Grid spacing={1} className="pb-3 ">
        <Grid item s={12} className="pb-3 d-flex justify-content-center">
          <BarChart />
        </Grid>

        <Grid
          s={12}
          item
          container
          direction="column"
          justifyContent="center"
          className="border-top pt-3"
        >
          <div>
            <Grid item s={12} className="pb-3">
              <ProductionOrders />
            </Grid>
            <Grid item s={12} className="pt-3">
              <PurchaseOrders />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
