import React, { useState, useEffect } from "react";

import useFetchAxios from "../../hooks/useFetchAxios4";
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";

import { partFilterSetup } from "../filters/config/part_totals/partTotalsSelection";
import PartTotalHome from "./partTotalHome";
import axiosInstance from "../../hooks/usePostAxios";

export default function PartTotalMain() {
  const { push } = useHistory();
  const { user_id } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [freightType, setFreightType] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "",
    requested_quantity_gt: "",
    requested_quantity_lt: "",
    production_quantity_gt: "",
    production_quantity_lt: "",
    balance_gt: "",
    balance_lt: "",
  });
  const [postData, setPostData] = useState([]);
  const [orderButton, setOrderButton] = useState(false);
  const load_url = `esd/parttotals/?search=${filters.search}&ordering=${filters.sort}&requested_quantity_gt=${filters.requested_quantity_gt}&requested_quantity_lt=${filters.requested_quantity_lt}&production_quantity_gt=${filters.production_quantity_gt}&production_quantity_lt=${filters.production_quantity_lt}&balance_gt=${filters.balance_gt}&balance_lt=${filters.balance_lt}&user=${user_id}`;
  const { loading, data, setUrl, setData } = useFetchAxios(load_url);
  useEffect(() => {
    const setData = () => {
      setUrl(load_url);
    };
    setData();
  }, [load_url, setUrl]);

  const createPostandUpdate = () => {
    setLoadingOrder(true);
    let [arr, send_arr] = confirmData();
    createPurchaseOrder(arr);
    subtractQuantities(send_arr);
  };

  const createPurchaseOrder = (arr) => {
    let today = new Date();
    let date = today.toLocaleDateString();
    axiosInstance
      .get(`https://danielguardado.pythonanywhere.com/esd/customers/${user_id}`)
      .then((resp) => {
        let freight = freightType ? "AIR" : "SEA";
        const PO = {
          customer: resp.data.id,
          name: `${resp.data.alias}_${date}`,
          freight_type: freight,
          status: "P",
          file_status: "P",
        };
        axiosInstance
          .post(
            `https://danielguardado.pythonanywhere.com/esd/purchaseorders/`,
            PO
          )
          .then((resp) => {
            addLines(resp.data.id, arr);
          });
      });
  };

  const addLines = (id, arr) => {
    arr.forEach((item) => {
      axiosInstance.post(
        `https://danielguardado.pythonanywhere.com/esd/purchaseorders/${id}/items/`,
        item
      );
    });
    setLoadingOrder(false);
    push(`/purchaseorders/${id}/items`);
  };

  const confirmData = () => {
    const send_arr = [];
    const po_arr = [];
    data.results.forEach((item) => {
      if (!item.add) {
        let qty = item.quantity;
        let part_id = item.id;
        po_arr.push({ part_id: part_id, quantity: qty });
        item.prodorderitem_part.forEach((pro_order) => {
          if (qty >= pro_order.requested_quantity) {
            send_arr.push({
              production_quantity: 0,
              requested_quantity: 0,
              status: "C",
              production_order: pro_order.productionorder,
              production_order_item: pro_order.id,
            });
            qty -= pro_order.requested_quantity;
          } else if (pro_order.production_quantity - qty > 0) {
            send_arr.push({
              production_quantity: pro_order.production_quantity - qty,
              requested_quantity: pro_order.requested_quantity - qty,
              status: "F",
              production_order: pro_order.productionorder,
              production_order_item: pro_order.id,
            });
          } else if (pro_order.production_quantity - qty === 0) {
            send_arr.push({
              production_quantity: pro_order.production_quantity - qty,
              requested_quantity: pro_order.requested_quantity - qty,
              status: "P",
              production_order: pro_order.productionorder,
              production_order_item: pro_order.id,
            });
          } else {
            send_arr.push({
              production_quantity: pro_order.production_quantity,
              requested_quantity: pro_order.requested_quantity,
              status: "P",
              production_order: pro_order.productionorder,
              production_order_item: pro_order.id,
            });
          }
        });
      }
    });
    // send_arr.forEach((item) => {
    //   axiosInstance.patch(
    //     `https://danielguardado.pythonanywhere.com/esd/productionorders/${item.production_order}/items/${item.production_order_item}/`,
    //     {
    //       requested_quantity: item.requested_quantity,
    //       production_quantity: item.production_quantity,
    //       status: item.status,
    //     }
    //   );
    // });
    return [po_arr, send_arr];
  };

  const subtractQuantities = (send_arr) => {
    send_arr.forEach((item) => {
      axiosInstance.patch(
        `https://danielguardado.pythonanywhere.com/esd/productionorders/${item.production_order}/items/${item.production_order_item}/`,
        {
          requested_quantity: item.requested_quantity,
          production_quantity: item.production_quantity,
          status: item.status,
        }
      );
    });
  };

  const [counter, setCounter] = useState(0);

  return (
    <PartTotalHome
      data={data}
      setData={setData}
      setUrl={setUrl}
      setPostData={setPostData}
      postData={postData}
      setOrderButton={setOrderButton}
      loading={loading}
      filter={true}
      filters={filters}
      setFilters={setFilters}
      partFilterSetup={partFilterSetup}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      counter={counter}
      setCounter={setCounter}
      createPostandUpdate={createPostandUpdate}
      loadingOrder={loadingOrder}
      setLoadingOrder={setLoadingOrder}
      orderButton={orderButton}
      freightType={freightType}
      setFreightType={setFreightType}
    />
  );
}
