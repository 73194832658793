export const sites = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "FBA",
    label: "FBA",
  },
  {
    value: "MAIN",
    label: "Main",
  },

  {
    value: "VENDOR",
    label: "Vendor",
  },
  {
    value: "ROUGH_COUNTRY",
    label: "Rough Country",
  },
];

export const sort = [
  {
    value: "",
    label: "Select",
  },

  {
    value: "total_quantity_inv",
    label: "Total QTY - Ascending",
  },
  {
    value: "-total_quantity_inv",
    label: "Total QTY - Descending",
  },
  {
    value: "total_quantity_inv_order",
    label: "Qty on order - Ascending",
  },
  {
    value: "-total_quantity_inv_order",
    label: "Qty on order - Descending",
  },
  {
    value: "total_quantity_inv_on_hand",
    label: "Qty on hand - Ascending",
  },
  {
    value: "-total_quantity_inv_on_hand",
    label: "Qty on hand - Descending",
  },
];
