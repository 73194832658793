import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import { buttonStyleCommit } from "../styles/styleConfig";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const TableHeadItem = ({ item, commitAll, uncommitAll, ...props }) => {
  const [status, setStatus] = useState(true);
  const allCheck = () => {
    if (status) {
      commitAll(props.data2);
    } else {
      uncommitAll();
    }
    setStatus(!status);
  };

  return item.id === "submit_pf" ? (
    <TableCell
      scope="col"
      key={item.id}
      align={item.align}
      style={{
        minWidth: item.minWidth,
        color: "white",
        backgroundColor: "#212529",
      }}
      onClick={() => allCheck()}
    >
      <Tooltip title="Add All">
        <IconButton
          onClick={() => allCheck()}
          style={buttonStyleCommit}
          id="submit"
          type="submit"
          variant="outlined"
        >
          {status ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
      </Tooltip>
    </TableCell>
  ) : (
    <TableCell
      scope="col"
      key={item.id}
      align={item.align}
      style={{
        minWidth: item.minWidth,
        color: "white",
        backgroundColor: "#212529",
      }}
    >
      {item.label}
    </TableCell>
  );
};

export default TableHeadItem;
