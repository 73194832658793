export const theadData = [
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  {
    id: "customer_company",
    label: "Vendor",
    minWidth: 100,
    align: "center",
  },
  { id: "part_count", label: "Part Count", minWidth: 200, align: "center" },
  {
    id: "totals_quantity",
    label: "Total Parts",
    minWidth: 200,
    align: "center",
  },
  {
    id: "date_created",
    label: "Date Created",
    minWidth: 200,
    align: "center",
  },
  { id: "date_closed", label: "Date Closed", minWidth: 200, align: "center" },
  { id: "status", label: "Status", minWidth: 200, align: "center" },
];
